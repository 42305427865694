import * as at from '../../../../actionTypes.js';
import { remoteAction } from '../../../../services/actionService.js';
import api, { skedApi, } from '../../../../services/api.js';
import axios from 'axios';
import { notification, delay } from '../../../../services/utilities.js';
import {
  reset,
  getCurrentClient,
  backToClient,
} from '../../../Clients/components/client-dialog/client-dialog.actions.jsx';
import * as R from 'ramda';
import {
  now,
  formatStandard,
} from '../../../../services/joda.js';

export const read = (id) => api.post(`sentmsg/read/${id}`, { hasRead: true, dummy: [] });

const getAttachments = (attachmentIds) => api.post('attachment', { attachmentIds });

const attachAttachemnts = (data) => {
  return Promise.all(data.data.map((d) => {
    const attachmentIds = R.pathOr(null, ['smsData', 'attachmentIds'], d);
    if (attachmentIds && attachmentIds.length > 0) {
      return getAttachments(attachmentIds).then(({ officeAttachments }) => {
        return R.assocPath(['smsData', 'attachments'], officeAttachments, d);
      });
    }
    return d;
  }));
};

export const loadMessage = ({
  clientId,
  page = 1,
  perPage = 50,
  shouldRead = false,
  unread = false,
  archived = false,
}) => remoteAction({
  type: at.MESSAGE_THREAD_REMOTE_GET,
  action: () => Promise.all([
    api.post('sentmsg/query', {
      page,
      perPage,
      query: {
        toOrFromClient: clientId,
        isToOrFromClient: [],
        smsOnly: [],
        includeDeleted: [],
      }
    }),
    api.get(`client/${clientId}`),
    api.get(`client/optout/${clientId}`),
    api.post('sentmsg/query/conversation',
      {
        page: 1,
        perPage: 1,
        query: {
          clientId,
          unread: unread ? [] : undefined,
          archived: archived ? [] : undefined,
        }
      })
  ]).then(([data, client, clientOptOut, conversation]) => {
    return attachAttachemnts(data).then((newData) => {
      Promise.all(data.data.map((message) => {
        if ((!R.pathOr(true, ['to', 'ToOffice', 'hasRead'], message) ||
             !R.pathOr(true, ['from', 'FromOffice', 'hasRead'], message)) &&
             shouldRead) {
          read(message.msgId);
        }
      }));

      return R.pipe(
        R.assoc('data', newData),
        R.merge({
          perPage,
          clientOptOut,
          client,
          conversationId: conversation.data[0]?.msgId
        }))(data);
    });
  }),
});

export const loadMoreMessages = ({ clientId, page = 1, perPage = 50 }) => {
  const action = async () => {
    const data = await api.post('sentmsg/query', {
      page,
      perPage,
      query: {
        toOrFromClient: clientId,
        isToOrFromClient: [],
        smsOnly: [],
        includeDeleted: [],
      }
    });
    const messages = await attachAttachemnts(data);
    return { messages, page: data.page };
  };

  return remoteAction({
    type: at.MESSAGE_THREAD_REMOTE_GET_MORE,
    action,
  });
};

export const messageEvent = null;

export const getUnreadMessages = () => (dispatch, getStore) => {
  // Just get unread messages
  return api.get('sentmsg/sms/unread').then((response) => {
    const admin = R.path(['login', 'admin'], getStore());
    if (admin) {
      return;
    }
    const state = getStore();
    const hasNewChatFeature = R.includes('NewSMSThreads', R.path(['login', 'features'], state));
    const hasLeads = R.includes('Leads', R.path(['login', 'features'], state));
    const oldLayout = R.path(['login', 'oldLayout'], state);

    let data = [];
    let leadsData = [];
    if (oldLayout) {
      data = response.filter(message => !message.client.isLead && !!message.client.firstName);
      leadsData = response.filter(message => message.client.isLead && !!message.client.firstName);
    } else {
      data = response.filter((message) => {
        if (!message.client.firstName) {
          return false;
        }
        if (message.client.isLead) {
          return hasLeads;
        }
        return true;
      });
    }

    // The user is opening an inbox page so they'll see the unread notifications
    // No need to notify them
    const isOnInboxPage = window.location.href.includes('inbox');
    const isOnLeadInboxPage = !window.location.href.includes('sms-leads-inbox');
    if (!isOnInboxPage || isOnLeadInboxPage) {
      if (data.length === 1) {
        const message = R.head(data);
        const title = 'New message from ' + message.client.firstName + ' ' + message.client.lastName + '!';
        const body = {
          body: message.smsData.body,
          tag: message.msgId,
        };
        const thread = hasNewChatFeature ? 'chat' : 'thread';
        const location = `/#/inbox/${thread}/${message.client.id}`;
        console.log('send notification');
        notification(title, body, location);
      } else if (data.length > 1) {
        const title = 'You have ' + data.length + ' new messages!';
        const body = { tag: data[1].client.id + data[0].client.id };
        notification(title, body, '/#/inbox');
      }
    }

    if (!isOnLeadInboxPage && hasLeads) {
      if (leadsData.length === 1) {
        const message = R.head(leadsData);
        const title = 'New message from ' + message.client.firstName + ' ' + message.client.lastName + '!';
        const body = {
          body: message.smsData.body,
          tag: message.msgId,
        };
        const thread = hasNewChatFeature ? 'chat' : 'thread';
        const url = oldLayout ? '/#/sms-leads-inbox' : '/#/inbox';
        const location = `${url}/${thread}/${message.client.id}`;
        console.log('send notification');
        notification(title, body, location);
      } else if (leadsData.length > 1) {
        const title = 'You have ' + leadsData.length + ' new messages!';
        const body = { tag: leadsData[1].client.id + leadsData[0].client.id };
        const url = oldLayout ? '/#/sms-leads-inbox' : '/#/inbox';
        notification(title, body, url);
      }
    }
    dispatch({
      type: at.MESSAGE_THREAD_PATCH,
      data: {
        unread: data.length,
        unreadMessages: data,
        leadsUnread: leadsData.length,
        leadsUnreadMessages: leadsData,
      }
    });
  }).catch((error) => {
    console.log('error getting unread messages', error);
  });
};

export const handleReplyEvent = event => (dispatch, getStore) => {
  const admin = R.path(['login', 'admin'], getStore());

  if (admin) {
    return;
  }

  const message = typeof event.data === 'string' ? JSON.parse(event.data) : event;
  const noName = !message.client.firstName;
  if (noName) {
    return;
  }
  console.log(JSON.stringify(message, null, 2));
  const state = getStore();
  const messages = R.path(['messageThread', 'messages'], state);
  const clientId = R.pathOr(null, ['messageThread', 'client', 'id'], state);
  const hasNewChatFeature = R.includes('NewSMSThreads', R.path(['login', 'features'], state));
  const hasLeads = R.includes('Leads', R.path(['login', 'features'], state));
  const contactType = R.pathOr(null, ['messages', 'contactType'], state);
  const oldLayout = R.pathOr(null, ['login', 'oldLayout'], state);
  const messageFromLead = message.client.isLead;

  if (!hasLeads && messageFromLead) {
    return;
  }

  const isMsgForCurrentClient =
    // Is not a duplicate
    !R.includes(message, messages) &&
    // Is on the message page
    (R.includes(`inbox/thread/${clientId}`, window.location.href) ||
      R.includes(`inbox/chat/${clientId}`, window.location.href)) &&
    // Page has finished loading
    message.client.id === clientId;

  const isMsgForCurrentLead =
    // Is not a duplicate
    !R.includes(message, messages) &&
    // Is on the message page
    (R.includes(`sms-leads-inbox/thread/${clientId}`, window.location.href) ||
      R.includes(`sms-leads-inbox/chat/${clientId}`, window.location.href)) &&
    // Page has finished loading
    message.client.id === clientId;

  attachAttachemnts({ data: [message] }).then(([newMessage]) => {
    dispatch({
      type: at.MESSAGE_THREAD_NEW_REPLY,
      data: newMessage,
      contactType,
      oldLayout,
    });
  }).catch(() => {
    dispatch({
      type: at.MESSAGE_THREAD_NEW_REPLY,
      data: message,
      contactType,
      oldLayout,
    });
  });

  const messageTitle = `New message from ${message.client.firstName} ${message.client.lastName}!`;
  const messageOptions = {
    body: message.smsData && message.smsData.body,
    tag: message.msgId,
    messageId: message.msgId,
    clientId: message.client.id
  };
  const thread = hasNewChatFeature ? 'chat' : 'thread';
  const messageLocation = `/#/inbox/${thread}/${message.client.id}`;

  console.log('reply message event', { isMsgForCurrentClient, thread });

  if (!message.from.FromOffice && !messageFromLead &&
    (!isMsgForCurrentClient || (isMsgForCurrentClient && !document.hasFocus()))) {
    notification(messageTitle, messageOptions, messageLocation);
  }
  if (!message.from.FromOffice && messageFromLead &&
    (!isMsgForCurrentClient || (isMsgForCurrentClient && !document.hasFocus()))) {
    const messageLeadLocation = `/#/sms-leads-inbox/${thread}/${message.client.id}`;
    notification(messageTitle, messageOptions, oldLayout ? messageLeadLocation : messageLocation);
  }

  if (oldLayout && (isMsgForCurrentClient || isMsgForCurrentLead)) {
    read(message.msgId);
  }

  if (message.from.FromOffice) {
    dispatch(pollForStatus({
      msgId: message.msgId,
      tmpId: message.msgId,
    }));
  }
};

export const handleReadEvent = (event) => (dispatch, getStore) => {
  const parsed = typeof event.data === 'string' ? JSON.parse(event.data) : event;
  if (!parsed.client?.firstName) {
    return;
  }
  const oldLayout = R.path(['login', 'oldLayout'], getStore());
  dispatch({
    type: at.MESSAGE_THREAD_READ_MESSAGE,
    data: parsed,
    oldLayout,
  });
  const inbox = R.path(['messages', 'inbox'], getStore());
  dispatch({
    type: at.MESSAGES_PATCH,
    data: {
      inbox: inbox.map((msg) => {
        if (msg.msgId === parsed.msgId) {
          return {
            ...msg,
            unread: R.hasPath(['to', 'ToOffice'], parsed) ?
              !parsed.to.ToOffice.hasRead :
              !parsed.from.FromOffice.hasRead
          };
        }
        return msg;
      }),
    },
  });
};

export const checkForMessages = () => (dispatch, getStore) => {
  const authed = R.path(['session', 'access_token'], getStore());
  if (!authed) {
    return;
  }
  return dispatch(getUnreadMessages());
};


let removeListener;
export const closeMessageListener = () => {
  if (removeListener) {
    removeListener();
  }
};
export const initMessageListener = (socket) => (dispatch) => {
  if (!socket) {
    return removeListener = () => null;
  }
  const messageHandler = (event) => {
    const parsed = JSON.parse(event.data);
    if (parsed.tag === 'MessageThread') {
      if (parsed.contents.tag === 'NewReply' &&
        parsed.contents.contents &&
        parsed.contents.contents.smsData
      ) {
        dispatch(handleReplyEvent(parsed.contents.contents));
      } else if (parsed.contents.tag === 'MsgRead') {
        dispatch(handleReadEvent(parsed.contents.contents));
      }
    }
  };
  socket.addEventListener('message', messageHandler);

  removeListener = () => {
    socket.removeEventListener('message', messageHandler);
  };

  return dispatch(getUnreadMessages());
};

export const handleArchiverCurrent = (messages, isArchive) => (dispatch, getStore) => {
  const conversationId = R.path(['messageThread', 'conversationId'], getStore());
  const selected = R.find(({ selected, msgId }) => selected && msgId === conversationId)(messages);
  if (selected) {
    return dispatch({
      type: at.MESSAGE_THREAD_PATCH,
      data: {
        isArchived: isArchive,
      }
    });
  }
};

export const messageThreadPatch = (data) => ({
  type: at.MESSAGE_THREAD_PATCH,
  data,
});

export const selectClient = (client) => (dispatch) => {
  dispatch(getCurrentClient(client));
  return dispatch({
    type: at.MESSAGE_THREAD_PATCH,
    data: {
      busy: false,
      state: 'CLIENT_SELECT',
    }
  });
};

const pollMessageUntilSent = (id, ms = 512) => {
  console.log('poll message status', id, ms);
  return delay(ms).then(
    () => api.get(`sentmsg/${id}`)
  ).then(msg => {
    if (msg.smsStatus === 'Delivered' || msg.smsStatus === 'Failed' || ms > 30e3) {
      const attachmentIds = R.pathOr(false, ['smsData', 'attachmentIds'], msg);
      if (attachmentIds) {
        return getAttachments(attachmentIds).then(({ officeAttachments }) => {
          return {
            ...msg,
            done: true,
            smsData: {
              ...msg.smsData,
              attachments: officeAttachments,
            },
          };
        });
      }
      return msg;
    }
    return pollMessageUntilSent(id, ms * 4);
  });
};

export const pollForStatus = ({ msgId, tmpId }) => dispatch => {
  return pollMessageUntilSent(msgId).then((msg) => {
    dispatch({
      type: at.MESSAGE_THREAD_REMOTE_SEND_MESSAGE,
      state: 'RESPONSE',
      data: { msg, tmpId }
    });
  });
};

export const sendMessageQuick = ({ body, attachments = [] }, clientId, pushMessage = null) => {
  const tzNow = formatStandard(now('tz'));
  const tmpId = new Date().getTime();
  const action = () => api.post('sentmsg', {
    sms: body || attachments ? {
      body: body || '',
      attachmentIds: attachments
        .filter(({ attachment }) => attachment)
        .map(({ attachment }) => attachment.attachmentId),
    } : undefined,
    push: pushMessage,
    sendTo: clientId,
  }).then(msg => ({
    // Include this to remove the optimistic update
    tmpId,
    msg: {
      ...msg,
      // This is empty from some reason...
      // Server does not send these
      smsStatus: 'Pending',
      smsData: {
        body,
        attachmentIds: []
      }
    }
  }));

  return remoteAction({
    type: at.MESSAGE_THREAD_REMOTE_SEND_MESSAGE,
    // For an optimistic update
    params: {
      tmpId,
      msgId: tzNow,
      to: {
        ToClient: [],
      },
      created: tzNow,
      smsStatus: 'Pending',
      smsData: {
        body,
        attachmentIds: [],
      },
    },
    action
  });
};

export const sendMessage = (bodyAndAttachments, id) => (dispatch) => {
  return dispatch(sendMessageQuick(bodyAndAttachments, id)).then(data => {
    return dispatch(pollForStatus({ msgId: data.msg.msgId, tmpId: data.tmpId }));
  });
};

export const selectClientDialog = (client) => (dispatch) =>
  dispatch(getCurrentClient(client));

export const backToMessage = () => (dispatch) => {
  dispatch(reset());
  return dispatch({
    type: at.MESSAGE_THREAD_PATCH,
    data: {
      state: 'VIEW',
    }
  });
};

export const back = () => (dispatch) => dispatch(backToClient());

export const getPhis = (messages) => {
  const fileIds = R.flatten(messages.map(({ smsData }) => {
    return smsData && R.flatten((smsData.phi || []).map(({ availableFiles }) => {
      return availableFiles.map(({ fileId }) => fileId);
    }).filter((p) => p));
  }));
  return api.post('phi2/getUrls', { fileIds }).then((d) => d.files.map(({ Url }) => Url));
};

export const uploadAttachments = (files, setFiles) => {
  const requests = files.map(({ file }, ind) => {
    const config = {
      headers: {
        'Authorization': skedApi.defaults.headers.common.Authorization,
        'X-As-Office': skedApi.defaults.headers.common['X-As-Office'],
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setFiles(R.update(ind, { file, progress: percentCompleted }, files));
      }
    };
    const formData = new FormData();
    formData.append('attachment', file, file.name);
    return axios.post(
      `${process.env.API_URL}/attachment/upload`,
      formData,
      config
    ).catch((e) => {
      console.error(e);
    });
  });
  return Promise.all(requests).then((data) => {
    const attachmentDatas = R.pipe(
      R.map(R.pathOr([], ['data', 'officeAttachments'])),
      R.flatten,
    )(data);
    setFiles(files.map((file, ind) => {
      const hasData = attachmentDatas[ind];
      if (hasData) {
        return {
          ...file,
          progress: 100,
          attachment: hasData,
        };
      } else {
        return file;
      }
    }));
    return null;
  });
};
