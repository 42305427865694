import * as at from '../../../../actionTypes.js';
import * as R from 'ramda';

const init = {
  busy: false,
  error: {},
  snackbar: false,
  timeSetting: 'Never',
  sendTextNotifications: true,
  allowGrouping: true,
  undeliveredSetting: false,
  undeliveredSettingTime: null,
  geofenceSetting: 'Off',
  messageText: '',
  verifiedEmail: '',
  emailStatus: 'NotRequested',
};

export default function reducer(general = init, action) {
  switch (action.type) {
    case at.GENERAL_PATCH: {
      return R.merge(general, action.data);
    }
    case at.GENERAL_REMOTE_GET_SETTINGS:
      if (action.state === 'REQUEST') {
        return R.merge(general, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        console.log(action.data);
        return R.merge(general, {
          busy: false,
          timeSetting: R.pipe(
            R.nth(0),
            R.propOr({}, 'data'),
            R.propOr('Never', 'timeSetting')
          )(action.data),
          sendTextNotifications: R.pipe(
            R.nth(1),
            R.propOr({}, 'data'),
            R.propOr(false, 'sendTextNotifications')
          )(action.data),
          allowGrouping: R.pipe(
            R.nth(1),
            R.propOr({}, 'data'),
            R.propOr(false, 'allowGrouping')
          )(action.data),
          undeliveredSetting: R.pipe(
            R.nth(2),
            R.propOr({}, 'data'),
            R.propEq('time', null),
            R.not
          )(action.data),
          undeliveredSettingTime: R.pipe(
            R.nth(2),
            R.propOr({}, 'data'),
            R.prop('time'),
            R.ifElse(
              R.identity,
              R.dropLast(3),
              R.always(null),
            ),
          )(action.data),
          verifiedEmail: R.pipe(
            R.nth(4),
            R.propOr({}, 'data'),
            R.prop('email'),
          )(action.data),
          emailStatus: R.pipe(
            R.nth(4),
            R.propOr({}, 'data'),
            R.prop('status'),
          )(action.data),
        });
      } else if (action.state === 'ERROR') {
        return R.merge(general, {
          busy: false,
          error: action.error,
        });
      }
      return general;
    case at.GENERAL_REMOTE_GET_VERIFIED_EMAIL_STATUS:
      if (action.state === 'REQUEST') {
        return R.merge(general, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        return R.merge(general, {
          busy: false,
          verifiedEmail: R.propOr('', 'email', action.data),
          emailStatus: R.propOr('NotRequested', 'status', action.data),
        });
      } else if (action.state === 'ERROR') {
        return R.merge(general, {
          busy: false,
          error: action.error,
        });
      }
      return general;
    case at.GENERAL_SAVE:
      if (action.state === 'REQUEST') {
        return R.merge(general, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        return R.merge(general, {
          snackbar: true,
          busy: false,
        });
      } else if (action.state === 'ERROR') {
        return R.merge(general, {
          busy: false,
        });
      }
      return general;
    case at.GENERAL_CONFIRM_EMAIL:
      if (action.state === 'REQUEST') {
        return R.merge(general, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        return R.merge(general, {
          busy: false,
          emailStatus: 'Success',
        });
      } else if (action.state === 'ERROR') {
        return R.merge(general, {
          busy: false,
        });
      }
      return general;
    default:
      return general;
  }
}
