export type ItemType = {
  title: string;
  hideSpark?: boolean;
  items: {
    title: string;
    description?: string;
    noneEHRTitle?: string;
    noneEHRDescription?: string;
    link?: string;
    className?: string;
    features?: string[];
    hideFeatures?: string[];
    onlyAdmin?: boolean;
    notAdminOrAgency?: boolean;
    showAdminOrOfficeAdminOrAgency?: boolean;
    switchToOldLook?: boolean;
    showSmsNotification?: boolean;
    showNotification?: boolean;
    hideSpark?: boolean;
  }[]
}

type ItemsObj = {
  general: ItemType;
  scheduling: ItemType;
  tools: ItemType;
  business: ItemType;
  app: ItemType;
  integrations: ItemType;
  appearance: ItemType;
}

export const ITEMS: ItemsObj = {
  general: {
    title: 'GENERAL',
    items: [
      {
        title: 'Update Profile',
        description: 'Update user information such as name and email',
        link: '/settings/profile',
        notAdminOrAgency: true,
        className: 'sked-test-settings-update-profile',
      },
      {
        title: 'Billing',
        description: 'Update and customize your billing information',
        link: '/settings/subscription',
        className: 'sked-test-settings-billing',
      },
      {
        title: 'EHR Settings',
        noneEHRTitle: 'Other Settings',
        noneEHRDescription: 'Manage other organization settings',
        description: 'Access and modify your EHR-Specific options',
        link: '/settings/integration',
        className: 'sked-test-settings-ehr-settings',
      },
      {
        title: 'Notifications',
        description: 'Stay updated and control how you receive notifications',
        link: '/settings/general',
        showNotification: true,
        className: 'sked-test-settings-notifications',
      },
      {
        title: 'SMS Legal Settings',
        description: 'Enter required tax details for SMS message compliance',
        link: '/settings/sms-number',
        showSmsNotification: true,
        className: 'sked-test-settings-sms-legal-settings',
      },
      {
        title: 'SKED Client Installer',
        description: '',
        link: '/settings/client-installer',
        onlyAdmin: true,
      },
    ]
  },
  scheduling: {
    title: 'SCHEDULING',
    items: [
      {
        title: 'Appointment Types',
        description: 'Personalize appt. types, duration, color, etc.',
        link: '/professionals',
        className: 'sked-test-settings-appointment-types',
      },
      {
        title: 'Provider Schedule',
        description: 'Fine-tune provider schedules and associated appt. types',
        link: '/schedule',
        features: ['NPPortal', 'NPPortal2', 'SkedApp'],
        className: 'sked-test-settings-provider-schedule',
      },
      {
        title: 'Office Hours',
        description: 'General hours of operation',
        link: '/office-hours',
        features: ['NewCalendar', 'NPPortal'],
        className: 'sked-test-settings-office-hours',
        hideSpark: true,
      },
      {
        title: 'Special Office Hours',
        description: 'One time changes to hours of operation',
        link: '/special-office-hours',
        features: ['NPPortal', 'Autoresponder', 'NPPortal2'],
        className: 'sked-test-settings-special-office-hours',
      },
    ],
  },
  tools: {
    title: 'TOOLS',
    items: [
      {
        title: 'Office Events',
        description: 'Create Office Events to entice patients to visit',
        link: '/office-events',
        features: ['SkedApp'],
        className: 'sked-test-settings-office-events',
        hideSpark: true,
      },
      {
        title: 'Review Settings',
        description: 'Configure how patients are asked to leave a review',
        link: '/settings/reviews',
        features: ['Reviews'],
        className: 'sked-test-settings-reviews',
        hideSpark: true,
      },
      {
        title: 'Tags',
        description: 'Create custom tags to segment groups of contacts',
        link: '/tags',
        features: ['Campaigns'],
        className: 'sked-test-settings-tags',
        hideSpark: true,
      },
    ]
  },
  business: {
    title: 'BUSINESS',
    items: [
      {
        title: 'Organization',
        description: 'Add and change business info such as name and address',
        link: '/settings/business',
        hideFeatures: ['MultipleOfficeUsers'],
        showAdminOrOfficeAdminOrAgency: true,
        className: 'sked-test-settings-business',
      },
      {
        title: 'Admin Settings',
        description: 'Add and change business info such as name and address. Manage Users',
        link: '/settings/business',
        features: ['MultipleOfficeUsers'],
        showAdminOrOfficeAdminOrAgency: true,
        className: 'sked-test-settings-admin-settings',
      },
      {
        title: 'Locations',
        description: 'Handle multiple physical locations for your brand',
        link: '/settings/locations',
        features: ['Locations'],
        className: 'sked-test-settings-locations',
      },
    ]
  },
  app: {
    title: 'APP',
    items: [
      {
        title: 'Mobile App Settings',
        description: 'Personalize your patient app’s behavior and preferences',
        link: '/settings/app',
        features: ['SkedApp'],
        className: 'sked-test-settings-mobile-app-settings',
        hideSpark: true,
      },
    ]
  },
  integrations: {
    title: 'INTEGRATIONS',
    items: [
      {
        title: 'GoHighLevel Integration',
        description: 'Sync with GoHighLevel',
        link: '/settings/gohighlevel',
        className: 'sked-test-settings-ghl-integration',
        features: ['GoHighLevel']
      },
      {
        title: 'CLA Synapse',
        description: 'Sync scans and assessments to admin and patient app',
        link: '/settings/cla',
        features: ['CLA'],
        className: 'sked-test-settings-cla-integration',
        hideSpark: true,
      },
      {
        title: 'Google Reviews Integration',
        description: 'Sync with Google to display reviews on your website',
        link: '/settings/google-integration',
        className: 'sked-test-settings-google-review-integration',
        features: ['EmbeddedGoogleReviews'],
      },
    ]
  },
  appearance: {
    title: 'APPEARANCE',
    hideSpark: true,
    items: [
      {
        title: '',
        description: 'Want to go back to the old, familiar interface?',
        switchToOldLook: true,
      },
    ]
  },
};
