import React, { useRef, useEffect, useState } from 'react';
import * as R from 'ramda';

import { Card, Typography, Grid } from '@mui/material';
import { useSelector } from '../../reducers';

import { HelpDialog, useHelpDialog, HelpButton } from '../Help.component';
import HeaderMore from '../HeaderMore/HeaderMore.component';

import { useStyles } from './pageHeader.styles';

type BreakPoint = {
  width: number;
  mobileItems: number[];
}

type PageHeaderProps = {
  title?: string;
  pageId?: string;
  helpMaxWidth?: number;
  leftIcons?: Element[] | JSX.Element[];
  rightIcons?: Element[] | JSX.Element[];
  breakPoints?: BreakPoint[];
  onlyIconsWidth?: number;
  updateSize?: number;
  fixed?: boolean;
  getWidth?: (value: number) => void;
}

let lastCallTime: number;

const PageHeader = ({ title, pageId, leftIcons, rightIcons, breakPoints, onlyIconsWidth, updateSize, fixed, helpMaxWidth, getWidth }: PageHeaderProps) => {
  const classes = useStyles();
  const helpState = useHelpDialog();
  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(480);
  const [right, setRight] = useState([]);
  const [left, setLeft] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [mobileIcons, setMobileIcons] = useState<Element[] | JSX.Element[]>([]);

  const desktopOpen = useSelector(state => state.login.desktopOpen);

  const updateWidth = () => {
    const now = new Date().getTime();
    if (!lastCallTime || (now - lastCallTime >= 200)) {
      const headerWidth = ref.current?.offsetWidth;
      setWidth(headerWidth);
      getWidth?.(headerWidth);
      lastCallTime = now;
    }
  };

  useEffect(() => {
    if (!breakPoints) {
      setLeft(leftIcons);
      setRight(rightIcons);
      return;
    }
    const sorted = R.sortBy(R.prop('width'), breakPoints);
    const breakPoint = sorted.find(bp => bp.width >= width);
    if (breakPoint) {
      const leftSize = leftIcons?.length || 0;
      const rightSize = rightIcons?.length || 0;
      const mobileItems = [] as unknown as object[];
      breakPoint.mobileItems.forEach(item => {
        if (item < leftSize) {
          const icon = leftIcons[item];
          mobileItems.push(icon as unknown as object);
        } else if (item - leftSize < rightSize) {
          const icon = rightIcons[item - leftSize];
          mobileItems.push(icon as unknown as object);
        }
      });
      const leftRest = R.without(mobileItems, leftIcons || []);
      const rightRest = R.without(mobileItems, rightIcons || []);
      setLeft(leftRest as unknown as Element[] | JSX.Element[]);
      setRight(rightRest as unknown as Element[] | JSX.Element[]);
      setMobileIcons(mobileItems as unknown as Element[] | JSX.Element[]);
      setShowMore(!!mobileItems.length);
    } else {
      setLeft(leftIcons);
      setRight(rightIcons);
      setMobileIcons([]);
      setShowMore(false);
    }
  }, [breakPoints, width, rightIcons, leftIcons]);

  useEffect(() => {
    function handleResize() {
      updateWidth();
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    updateWidth();
  }, [desktopOpen, updateSize]);

  return (
    <Card className={`${classes.root} ${fixed ? classes.fixed : ''}`} ref={ref}>
      <HelpDialog
        pageId={pageId}
        handleClose={helpState.handleClose}
        open={helpState.isOpen}
        maxWidth={helpMaxWidth}
      />
      <Grid display="flex" alignItems="center">
        <Typography className={classes.title} variant='h4'>{title}</Typography>
        {pageId ? <HelpButton handleOpen={helpState.handleOpen} newLayout /> : <>&nbsp;</>}
      </Grid>
      &nbsp;
      <Grid className={classes.icons} display="flex" alignItems="center" justifyContent="space-between" flex={1}>
        <Grid display="flex" alignItems="center">
          {onlyIconsWidth >= width ? left?.map((item) => item && React.cloneElement(item as React.ReactElement, { onlyIcon: true })) : left}
        </Grid>
        <Grid display="flex" alignItems="center">
          {onlyIconsWidth >= width ? right?.map((item) => item && React.cloneElement(item as React.ReactElement, { onlyIcon: true })) : right}
          {showMore && <HeaderMore items={mobileIcons} />}
        </Grid>
      </Grid>
    </Card>
  );
};

export default PageHeader;
