import React, { useEffect, useState } from 'react';
import { History } from 'history';
import { useDispatch } from 'react-redux';
import { Popover, Grid, Typography } from '@mui/material';
import * as R from 'ramda';

import * as loginActions from '../../routes/Login/login.actions';
import { SPARK_FEATURE } from '../../routes/Login/login.reducer';

import { useSelector } from '../../reducers';

import LogoIcon from '../../icons/Logo.icon';
import SparkLogo from '../../icons/SparkLogo.icon';
import EmailIcon from '../../icons/Email.icon';
import PhoneIcon from '../../icons/Phone.icon';

import HeaderButton from '../HeaderButton/HeaderButton.component';
import Section from './Section.component';
import { useStyles } from './settingsMega.styles';
import { ITEMS, ItemType } from './sectionItems';

type SettingsMegaProps = {
  anchorEl: HTMLSpanElement | null;
  open: boolean;
  onClose: () => void;
  history?: History;
}

const SettingsMega = ({ anchorEl, open, onClose, history }: SettingsMegaProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    features,
    admin,
    officeAdmin,
    agency,
    tax,
    verifiedEmailStatus,
    ehrSystem,
    theme,
  } = useSelector(state => ({
    features: state.login.features,
    admin: state.login.admin,
    officeAdmin: state.login.officeAdmin,
    agency: state.login.agency,
    tax: R.pathOr(null, ['login', 'tax'])(state),
    verifiedEmailStatus: R.pathOr('NotRequested', ['general', 'emailStatus'])(state),
    ehrSystem: state.login.office.ehrSystem,
    theme: state.login.theme,
  }));

  const [height, setHeight] = useState(700);

  const noneEHR = ehrSystem === 'None';
  const isSpark = R.includes(SPARK_FEATURE, features);

  const destroySession = () => dispatch(loginActions.destroySession());

  const handleChangeLayout = () => {
    dispatch(loginActions.changeLayout({ oldLayout: true }));
  };

  const handleHelp = () => {
    window.open('https://skedlife.zendesk.com/hc/en-us/', '_blank', 'noreferrer');
    onClose?.();
  };

  const updateHeight = () => {
    const windowHeight = window.innerHeight;
    setHeight(windowHeight);
  };

  const getActiveItems = (menuItem: ItemType) => {
    const activeItems = menuItem.items.filter(item => {
      if (item.onlyAdmin && !admin) {
        return false;
      }
      if (item.notAdminOrAgency && (admin || agency)) {
        return false;
      }
      if (item.hideSpark && isSpark) {
        return false;
      }
      if (item.hideFeatures?.find(feat => R.includes(feat, features))) {
        return false;
      }
      if (item.showAdminOrOfficeAdminOrAgency && (!admin && !agency && !officeAdmin)) {
        return false;
      }
      if (!features.length || !item.features?.length) {
        return true;
      }
      return item.features.find(feat => R.includes(feat, features));
    });
    return { ...menuItem, items: activeItems };
  };

  const tools = getActiveItems(ITEMS.tools);
  const integrations = getActiveItems(ITEMS.integrations);

  useEffect(() => {
    updateHeight();
  }, []);

  useEffect(() => {
    function handleResize() {
      updateHeight();
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return (
    <div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        classes={{ paper: classes.papper }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Grid>
          <Grid className={classes.header}>
            {theme === 'sked' ? <LogoIcon /> : <SparkLogo style={{ width: 66, height: 28 }} />}
            <Grid display="flex" alignItems="center">
              <HeaderButton
                color='primary'
                title='Logout'
                onClick={destroySession}
                className='sked-test-menu-header-logout'
              />
            </Grid>
          </Grid>
          <Grid display="flex" flexDirection="column" className={classes.mobile}>
            {Object.values(ITEMS).map(item => (
              <Section
                key={item.title}
                menuItem={getActiveItems(item)}
                onClose={onClose}
                history={history}
                noneEHR={noneEHR}
                hasSmsNotification={tax && !tax.ein}
                hasNotification={R.includes(verifiedEmailStatus, ['Requested', 'ErrorWithRequest'])}
                onSwitchToOldLook={handleChangeLayout}
                isSpark={isSpark}
              />
            ))}
          </Grid>
          <Grid
            className={classes.desktop}
            style={{
              maxHeight: `calc(${height}px - 190px)`,
              maxWidth: (tools.items.length || integrations.items.length) ? 680 : 618
            }}>
            <Grid display="flex" flexDirection="column" flex={1}>
              <Section
                menuItem={getActiveItems(ITEMS.general)}
                onClose={onClose}
                history={history}
                noneEHR={noneEHR}
                hasNotification={R.includes(verifiedEmailStatus, ['Requested', 'ErrorWithRequest'])}
                isSpark={isSpark}
                hasSmsNotification={tax && !tax.ein}
              />
              <Section
                menuItem={getActiveItems(ITEMS.business)}
                onClose={onClose}
                history={history}
                isSpark={isSpark}
              />
            </Grid>
            <Grid display="flex" flexDirection="column" className={classes.content} flex={1}>
              <Section menuItem={getActiveItems(ITEMS.scheduling)} onClose={onClose} history={history} isSpark={isSpark} />
              <Section menuItem={getActiveItems(ITEMS.app)} onClose={onClose} history={history} isSpark={isSpark} />
              <Section menuItem={getActiveItems(ITEMS.appearance)} onClose={onClose} history={history} onSwitchToOldLook={handleChangeLayout} isSpark={isSpark} />
            </Grid>
            {!!(tools.items.length || integrations.items.length) && (
              <Grid display="flex" flexDirection="column" flex={1}>
                <Section menuItem={tools} onClose={onClose} history={history} isSpark={isSpark} />
                <Section menuItem={integrations} onClose={onClose} history={history} isSpark={isSpark} />
              </Grid>
            )}
          </Grid>
          <Grid className={classes.footer}>
            <Grid className={`${classes.footerContent} ${classes.footerBorder}`}>
              <Typography className={classes.footerTitle}>Have questions?</Typography>
              <Typography className={classes.footerText}>Visit our <span onClick={handleHelp}>help center</span> for tutorials videos</Typography>
            </Grid>
            <Grid className={`${classes.footerContent} ${classes.lastDiv}`}>
              <Typography className={classes.footerTitle}>Need a human touch?</Typography>
              <Grid display="flex" flexWrap="wrap">
                <Grid display="flex" alignItems="center" marginRight={2.5}>
                  <EmailIcon />&nbsp;&nbsp;<a href='mailto:info@sked.life' className={classes.footerText}>info@sked.life</a>
                </Grid>
                <Grid display="flex" alignItems="center">
                  <PhoneIcon />&nbsp;&nbsp;<a href='tel:+18155954459' className={classes.footerText}>(815) 595-4459</a>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div className={classes.arrow} />
      </Popover>
    </div>
  );
};

export default SettingsMega;
