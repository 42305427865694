import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 600,
    fontSize: 24,
    color: 'rgb(0 0 0 / 77%)',
  },
  description: {
    marginTop: 10,
    fontWeight: 500,
    fontSize: 16,
    color: 'rgb(0 0 0 / 76%)',
  },
  text: {
    fontWeight: 400,
    fontSize: 14,
    color: 'rgb(0 0 0 / 70%)',
    marginTop: 10,
  },
  paper: {
    borderRadius: 12,
    border: '1px solid rgba(0, 0, 0, 0.1)',
  },
  icon: {
    width: 20,
    height: 20,
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    padding: 4,
    marginLeft: 5,
    marginRight: -5,
  },
  column: {
    width: '50%',
    padding: 10,
    [theme.breakpoints.down(650)]: {
      width: '100%'
    },
  },
  button: {
    marginTop: 20,
    width: '100%',

    '& svg': {
      marginRight: 5,
    }
  }
}));
