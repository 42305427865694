import * as at from '../../../../actionTypes.js';
import { remoteAction } from '../../../../services/actionService.js';
import api from '../../../../services/api.js';
import { loadingMessage } from '../MessagesNew/messages-new.actions';
import {
  reset,
  getCurrentClient,
  backToClient,
}
  from '../../../Clients/components/client-dialog/client-dialog.actions.jsx';
import * as R from 'ramda';
import { popup } from '../../../../services/Popup.js';


export const patch = (tab) => (dispatch) =>
  dispatch({
    type: at.VIEW_MESSAGE_PATCH,
    data: {
      partTab: tab,
    }
  });

export const selectMessage = (message) => (dispatch) =>
  dispatch({
    type: at.VIEW_MESSAGE_PATCH,
    data: {
      state: 'MESSAGE_SELECT',
      selectedMessage: message,
    }
  });

export const loadMessage = (id, tz) => remoteAction({
  type: at.VIEW_MESSAGE_REMOTE_GET,
  action: () => api.get(`message/${id}`).then((message) => {
    return loadingMessage(message, tz, 'view');
  }).catch((error) => {
    console.error(error);
    popup('Error!', 'Could not find message!');
  }),
});

export const getMessages = ({ id, page = 1, perPage = 25 }) => remoteAction({
  type: at.VIEW_MESSAGES_REMOTE_GET,
  action: () => api.post('sentmsg/query', {
    page,
    perPage,
    query: {
      forMessage: Number(id),
    }
  }).then((data) => R.assoc('perPage', perPage, data)),
});

export const selectClient = (client) => (dispatch) => {
  dispatch(getCurrentClient(client));
  return dispatch({
    type: at.VIEW_MESSAGE_PATCH,
    data: {
      busy: false,
      state: 'CLIENT_SELECT',
    }
  });
};

export const selectClientDialog = (client) => (dispatch) =>
  dispatch(getCurrentClient(client));

export const backToMessage = () => (dispatch) => {
  dispatch(reset());
  return dispatch({
    type: at.VIEW_MESSAGE_PATCH,
    data: {
      state: 'VIEW',
    }
  });
};

export const back = () => (dispatch) =>
  dispatch(backToClient());
