import * as at from '../../actionTypes.js';
import { merge, append, without } from 'ramda';

const init = {
  busy: false,
  opened: [],
  redirected: null,
  data: [
    {
      title: 'Getting Started',
      body: 'Complete the SKED Install with the Customer Support team to integrate SKED into your EHR system.',
      steps: [
        {
          title: 'Install SKED',
          body: 'Complete SKED install.',
          excludeEHRs: ['None'],
          plans: ['SkedAgency', 'SkedBasic', 'SkedStandard', 'SkedPro', 'SkedEnterprise'],
          link: null,
        },
        {
          title: 'Appointment Types',
          body: 'Finish customizing appointment types for each provider.',
          plans: ['SkedAgency', 'SkedBasic', 'SkedStandard', 'SkedPro', 'SkedEnterprise'],
          link: 'professionals',
        },
        {
          title: 'Schedule',
          body: 'Complete schedules for all providers.',
          plans: ['SkedStandard', 'SkedPro', 'SkedEnterprise'],
          link: 'schedule',
        },
        {
          title: 'App',
          body: 'Review the App tab.',
          plans: ['SkedStandard', 'SkedPro', 'SkedEnterprise'],
          link: 'settings/app',
        }
      ]
    },
    {
      title: 'Next Step',
      body: 'Set up your online patient portals and reviews.',
      steps: [
        {
          title: 'New Patient Experience',
          body: 'Set up an awesome onboarding experiencing for new patients.',
          plans: ['SkedPro', 'SkedEnterprise'],
          link: 'settings/new-patient-portal',
        },
        {
          title: 'Existing Patient Experience',
          body: 'Set up a system to keep your existing patients engaged with your office.',
          plans: ['SkedStandard', 'SkedPro', 'SkedEnterprise'],
          link: 'settings/webmodule',
        },
        {
          title: 'Reviews',
          body: 'Generate great reviews!',
          plans: ['SkedStandard', 'SkedPro', 'SkedEnterprise'],
          link: 'reviews',
        }
      ]
    },
    {
      title: 'Admin Training',
      body: 'Learn about SKED’s amazing features you have access to.',
      steps: [
        {
          title: 'App and Portal Usage',
          body: 'View what is happening within the SKED app and the online portal.',
          plans: ['SkedStandard', 'SkedPro', 'SkedEnterprise'],
          link: 'dashboard'
        },
        {
          title: 'Appointments Reports',
          body: 'Learn how to create custom reports!',
          plans: ['SkedPro', 'SkedEnterprise'],
          link: 'reports/appointments'
        },
        {
          title: 'Virtual Check-In',
          body: 'Introducing mobile appointment check-in.',
          plans: ['SkedStandard', 'SkedPro', 'SkedEnterprise'],
          link: 'waiting'
        },
        {
          title: 'Calendar',
          body: 'View & manage your schedule.',
          plans: ['SkedAgency', 'SkedBasic', 'SkedStandard', 'SkedPro', 'SkedEnterprise'],
          link: 'calendar-v2'
        },
        {
          title: 'Clients',
          body: 'View & manage your clients.',
          plans: ['SkedAgency', 'SkedBasic', 'SkedStandard', 'SkedPro', 'SkedEnterprise'],
          link: 'clients'
        },
        {
          title: 'Special Office Hours',
          body: 'Learn how to close the office.',
          plans: ['SkedStandard', 'SkedPro', 'SkedEnterprise'],
          link: 'special-office-hours'
        },
        {
          title: 'Campaigns',
          body: 'Learn how to create campaign tags.',
          plans: ['SkedStandard', 'SkedPro', 'SkedEnterprise'],
          link: 'campaigns'
        },
        {
          title: 'Office Events',
          body: 'Learn how to create/use office events in the app',
          plans: ['SkedStandard', 'SkedPro', 'SkedEnterprise'],
          link: 'office-events'
        },
      ]
    },
    {
      title: 'Messaging',
      body: 'Set up automated messages and learn how to contact your patients.',
      steps: [
        {
          title: 'SMS Inbox',
          body: 'Two-way communication with patients.',
          plans: ['SkedAgency', 'SkedBasic', 'SkedStandard', 'SkedPro', 'SkedEnterprise'],
          link: 'inbox'
        },
        {
          title: 'One-Time Messages',
          body: 'Learn how to contact your patient base.',
          plans: ['SkedAgency', 'SkedBasic', 'SkedStandard', 'SkedPro', 'SkedEnterprise'],
          link: 'messages'
        },
        {
          title: 'Reminders',
          body: 'There was no description for this...',
          plans: ['SkedAgency', 'SkedBasic', 'SkedStandard', 'SkedPro', 'SkedEnterprise'],
          link: 'reminder-messages'
        },
        {
          title: 'Birthday Messages',
          body: 'Make your practice members feel special on their Birthday!',
          plans: ['SkedAgency', 'SkedBasic', 'SkedStandard', 'SkedPro', 'SkedEnterprise'],
          link: 'birthday-messages'
        },
        {
          title: 'Re-Activation Messages',
          body: 'Reactivate patients!',
          plans: ['SkedPro', 'SkedEnterprise'],
          link: 'reactivation-messages'
        },
        {
          title: 'Rapid Messages',
          body: 'Send appointment confirmations.',
          plans: ['SkedAgency', 'SkedBasic', 'SkedStandard', 'SkedPro', 'SkedEnterprise'],
          link: 'change-messages'
        },
        {
          title: 'Auto Responders',
          body: 'No description.',
          plans: ['SkedStandard', 'SkedPro', 'SkedEnterprise'],
          link: 'autoresponder-messages'
        },
      ]
    }
  ],
  filterData: [],
};

export default (state = init, action) => {
  switch (action.type) {
    case at.TUTORIALS_EXPAND_SECTION: {
      return merge(state, { opened: append(action.data, state.opened) });
    }
    case at.TUTORIALS_COMPRESS_SECTION: {
      return merge(state, { opened: without([action.data], state.opened) });
    }
    case at.TUTORIALS_REDIRECT_STEP: {
      return merge(state, { redirected: action.data });
    }
    case at.TUTORIALS_PATCH_FILTER_DATA: {
      return merge(state, { filterData: action.data });
    }
    case at.TUTORIALS_GET_DATA: {
      if (action.state === 'REQUEST') {
        return merge(state, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        console.log(action.data);
        return merge(state, {
          busy: false,
          filterData: action.data.filterData,
          data: action.data.data,
        });
      } else if (action.state === 'ERROR') {
        return merge(state, {
          busy: false,
        });
      }
      return state;
    }
    case at.TUTORIALS_UPDATE_TUTORIAL: {
      if (action.state === 'REQUEST') {
        return merge(state, {
          busy: false,
        });
      } else if (action.state === 'RESPONSE') {
        console.log(action.data);
        return merge(state, {
          busy: false,
        });
      } else if (action.state === 'ERROR') {
        return merge(state, {
          busy: false,
        });
      }
      return state;
    }
    default:
      return state;
  }
};
