import React, { ComponentProps } from 'react';

const SparkIcon = ({ color, className, ...props }: ComponentProps<'svg'>) => {
  return (
    <svg className={className} {...props} xmlns="http://www.w3.org/2000/svg" width="18.486" height="31.179" viewBox="0 0 18.486 31.179">
      <defs>
        <linearGradient id="linear-gradient-spark-icon" y1="0.556" x2="1" y2="0.456" gradientUnits="objectBoundingBox">
          <stop offset="0" stop-color="#fb7906"/>
          <stop offset="1" stop-color="#fca930"/>
        </linearGradient>
      </defs>
      <path fill={color ? color : 'url(#linear-gradient-spark-icon)'} id="Path_10072" data-name="Path 10072" d="M12.7,0,0,17.344l.164.228H7.758L5.427,31.038l.369.141,12.69-17.328-.111-.239H10.738L13.073.15Z"/>
    </svg>
  );
};

export default SparkIcon;
