import * as actionTypes from '../../../../actionTypes.js';
import * as R from 'ramda';

const init = {
  busy: true,
  snackbarOpen: false,
  appointmentTypes: [],
  // professionals: [],
  selectedProfessionalId: 0,
  selectedAppointments: [],
  limits: {
    rescheduleLimit: null,
    futureScheduleLimit: null,
    allowSameDayScheduling: false,
    cutoffContactSetting: 'None',
  },
  cla: {
    newPatientCreate: false,
    allowedScans: [],
  },
  missedApptRemainInAppTime: null,
};

const appointmentIsEqual = (a, b) => a.id === b.id;

export default function reducer(state = init, action) {
  switch (action.type) {
    case actionTypes.SETTINGS_APP_PATCH: {
      return R.merge(state, action.data);
    }
    case actionTypes.SETTINGS_APP_GET_DATA: {
      if (action.state === 'RESPONSE')
        return {
          ...state,
          busy: false,
          professionals: action.data.professionals,
          appointmentTypes: action.data.appointmentTypes,
          selectedAppointments: action.data.selectedAppointments,
        };

      return state;
    }

    case actionTypes.SETTINGS_APP_PRO_NAME_HIDDEN: {
      if (action.state !== 'RESPONSE') return state;

      const findProfessionalById = professional =>
        professional.id === action.data.id;
      const index = R.findIndex(findProfessionalById, state.professionals);
      if (index === -1) return state;

      return {
        ...state,
        professionals: R.update(index, action.data, state.professionals),
      };
    }

    case actionTypes.SETTINGS_APP_SELECT_PRO: {
      return {
        ...state,
        selectedProfessionalId: action.data.selectedProfessionalId,
      };
    }

    case actionTypes.SETTINGS_APP_ADD_APPOINTMENT: {
      return {
        ...state,
        selectedAppointments: R.unionWith(
          appointmentIsEqual,
          state.selectedAppointments,
          [action.data.appointmentType],
        ),
      };
    }

    case actionTypes.SETTINGS_APP_ADD_ALL_APPOINTMENTS: {
      const belongsToProfessional = ({ professionalId }) =>
        action.data.professionalIds.includes(professionalId);

      return {
        ...state,
        selectedAppointments: R.unionWith(
          appointmentIsEqual,
          state.selectedAppointments,
          state.appointmentTypes.filter(belongsToProfessional),
        ),
      };
    }

    case actionTypes.SETTINGS_APP_REMOVE_ALL_APPOINTMENTS: {
      return {
        ...state,
        selectedAppointments: R.differenceWith(
          appointmentIsEqual,
          state.selectedAppointments,
          action.data.appointmentsToRemove,
        ),
      };
    }

    case actionTypes.SETTINGS_APP_REMOVE_APPOINTMENT: {
      return {
        ...state,
        selectedAppointments: state.selectedAppointments.filter(
          app => app.id !== action.data.appointmentId,
        ),
      };
    }

    case actionTypes.SETTINGS_APP_CLOSE_SNACKBAR: {
      return { ...state, snackbarOpen: false };
    }

    case actionTypes.SETTINGS_APP_SAVE: {
      return { ...state, snackbarOpen: action.state === 'RESPONSE' };
    }

    case actionTypes.SETTINGS_APP_GET_LIMITS: {
      if (action.state === 'REQUEST') {
        return R.merge(state, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        const {
          rescheduleLimit,
          futureScheduleLimit,
          allowSameDayScheduling,
          missedApptRemainInAppTime,
          cutoffContactSetting,
        } = action.data;
        return {
          ...state,
          busy: false,
          limits: {
            cutoffContactSetting:
              (
                R.isNil(cutoffContactSetting)
                ||
                cutoffContactSetting === 'Neither'
              ) ? 'None' : cutoffContactSetting,
            rescheduleLimit,
            futureScheduleLimit,
            allowSameDayScheduling,
          },
          missedApptRemainInAppTime,
        };
      } else if (action.state === 'ERROR') {
        return R.merge(state, {
          busy: false,
        });
      }
      return state;
    }

    case actionTypes.SETTINGS_APP_LIMITS_PATCH: {
      return {
        ...state,
        limits: R.merge(state.limits, action.data),
      };
    }
    case actionTypes.SETTINGS_APP_CLA_PATCH: {
      console.log(action.data);
      return {
        ...state,
        cla: action.data.cla,
      };
    }
    case actionTypes.SETTINGS_APP_GET_CLA: {
      if (action.state === 'REQUEST') {
        return R.merge(state, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        return {
          ...state,
          busy: false,
          cla: action.data,
        };
      } else if (action.state === 'ERROR') {
        return R.merge(state, {
          busy: false,
        });
      }
      return state;
    }
    case actionTypes.SETTINGS_APP_SAVE_CLA: {
      if (action.state === 'REQUEST') {
        return R.merge(state, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        return {
          ...state,
          busy: false,
          snackbarOpen: true,
        };
      } else if (action.state === 'ERROR') {
        return R.merge(state, {
          busy: false,
        });
      }
      return state;
    }
    default:
      return state;
  }
}
