import React from 'react';
import ReactDOM from 'react-dom';
import App from './App.jsx';
import hb from 'honeybadger-js';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunk from 'redux-thunk';
import reducers from './reducers';
import './styles/main.css';

hb.configure({
  apiKey: process.env.REACT_ADMIN_HB_KEY,
  environment: process.env.REACT_ADMIN_HB_ENV
});


const middleware = [thunk, reduxImmutableStateInvariant()];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducers,
  {},
  // Remove `reduxImmutableStateInvariant` for production
  composeEnhancers(applyMiddleware(
    ...middleware
  ))
);

// Needed for onTouchTap
// http://stackoverflow.com/a/34015469/988941
// injectTapEventPlugin();

ReactDOM.render((
  <Provider store={store}>
    <HashRouter>
      <App />
    </HashRouter>
  </Provider>
), document.getElementById('root'));
