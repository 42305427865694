import * as at from '../../../../actionTypes.js';
import { remoteAction } from '../../../../services/actionService.js';
import api from '../../../../services/api.js';
import { loadingMessage } from '../RecurringEdit/recurring-edit.actions.jsx';
import {
  reset,
  getCurrentClient,
  backToClient,
}
  from '../../../Clients/components/client-dialog/client-dialog.actions.jsx';
import * as R from 'ramda';
import { popup } from '../../../../services/Popup.js';

export const patch = (tab) => (dispatch) =>
  dispatch({
    type: at.VIEW_RECURRING_PATCH,
    data: {
      partTab: tab,
    }
  });

export const selectMessage = (message) => (dispatch) =>
  dispatch({
    type: at.VIEW_RECURRING_PATCH,
    data: {
      state: 'MESSAGE_SELECT',
      selectedMessage: message,
    }
  });

export const loadMessage = (id) => remoteAction({
  type: at.VIEW_RECURRING_REMOTE_GET,
  action: () => api.get(`message/${id}`).then((message) => {
    return loadingMessage(message, 'view');
  }).catch((error) =>{
    console.error(error);
    popup('Error!', 'Could not find message!');
  })
});

export const getMessages = ({
  id,
  page = 1,
  perPage = 25,
  hasFutureAppointment,
  reactivationSearch = false,
}) => remoteAction({
  type: at.VIEW_RECURRINGS_REMOTE_GET,
  action: () => api.post(reactivationSearch ? 'sentmsg/reactivation/query' : 'sentmsg/query', {
    page,
    perPage,
    query: {
      forMessage: Number(id),
      hasFutureAppointment,
    }
  }).then((data) => R.pipe(R.assoc('perPage', perPage), R.assoc('reactivationSearch', reactivationSearch))(data))
    .catch((error) =>{
      console.error(error);
      popup('Error!', 'Could not find message!');
    }),
});

export const selectClient = (client) => (dispatch) => {
  dispatch(getCurrentClient(client));
  return dispatch({
    type: at.VIEW_RECURRING_PATCH,
    data: {
      busy: false,
      state: 'CLIENT_SELECT',
    }
  });
};

export const selectClientDialog = (client) => (dispatch) =>
  dispatch(getCurrentClient(client));

export const backToMessage = () => (dispatch) => {
  dispatch(reset());
  return dispatch({
    type: at.VIEW_RECURRING_PATCH,
    data: {
      state: 'VIEW',
    }
  });
};

export const back = () => (dispatch) =>
  dispatch(backToClient());

export const updateAutomation = (index, automation) => ({
  type: at.VIEW_RECURRINGS_UPDATE_AUTOMATIONS,
  data: {
    index,
    automation,
  }
});


export const removeAutomation = (index) => ({
  type: at.VIEW_RECURRINGS_REMOVE_AUTOMATIONS,
  data: {
    index,
  }
});
