import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import {
  intersection, pipe, pathOr, assoc, merge, includes,
  prop, map, filter, isEmpty, not, fromPairs, flatten,
} from 'ramda';
import {
  Drawer, List, ListItem, ListItemIcon, ListItemText, ListSubheader,
  Typography, Chip, Hidden, Collapse, IconButton
} from '@mui/material';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DashboardIcon from '@mui/icons-material/Dashboard';
import TodayIcon from '@mui/icons-material/Today';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import ContactsIcon from '@mui/icons-material/Contacts';
import PeopleIcon from '@mui/icons-material/People';
import ScheduleIcon from '@mui/icons-material/Schedule';
import MessageIcon from '@mui/icons-material/Message';
import LoopIcon from '@mui/icons-material/Loop';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import LabelIcon from '@mui/icons-material/Label';
import RateReviewIcon from '@mui/icons-material/RateReview';
import HelpIcon from '@mui/icons-material/Help';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CakeIcon from '@mui/icons-material/Cake';
import NotificationsIcon from '@mui/icons-material/Notifications';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import LightBulbIcon from '@mui/icons-material/EmojiObjects';
import WebIcon from '@mui/icons-material/Web';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import ListIcon from '@mui/icons-material/List';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import RobotIcon from '@mui/icons-material/ToysOutlined';
import CampaignIcon from '@mui/icons-material/ListAlt';
import DescriptionIcon from '@mui/icons-material/Description';
import LeadsIcon from '@mui/icons-material/AssignmentInd';
import SparkIcon from '../../icons/Spark.icon';
import SparkLogo from '../../icons/SparkLogo.icon';
import Logo from '../../logo-white.png';

// type SubItem = {
//   path?: string;
//   name: string;
//   header?: string;
//   features?: string[];
//   onlyShowFeatures?: string[];
//   icon?: ReactElement;
// }

type NavItem = {
  path?: string;
  name: string;
  icon?: ReactElement;
  header?: string;
  features?: string[];
  onlyShowFeatures?: string[];
  hideFeatures?: string[];
  id?: string;
  isNew?: boolean;
  isBeta?: boolean;
  isActive?: boolean;
  isEnabled?: boolean;
  badge?: number;
  display?: string;
  items?: NavItem[]
}

type Header = {
  id: string;
  display: string;
  items: NavItem[];
}
const headers: Header[] = [
  { id: 'dashboard', display: 'DASHBOARD', items: [] },
  { id: 'current', display: 'QUICK ACCESS', items: [] },
  { id: 'messaging', display: 'MESSAGING', items: [] },
  { id: 'marketing', display: 'MARKETING', items: [] },
  { id: 'settings', display: 'SETTINGS', items: [] },
];

const navItems: NavItem[] = [
  {
    path: '/getting-started',
    name: 'Getting Started',
    icon: <LightBulbIcon />,
    header: 'dashboard',
    onlyShowFeatures: ['NewOfficeTutorial']
  },
  {
    path: '/dashboard',
    name: 'App and Portal Usage',
    icon: <DashboardIcon />,
    features: ['SkedApp', 'NPPortal2'],
    header: 'dashboard'
  },
  {
    path: '/reports/overview',
    name: 'Office Stats',
    icon: <TrendingUpIcon />,
    onlyShowFeatures: ['Analytics'],
    header: 'dashboard',
  },
  {
    name: 'Reports',
    icon: <ListIcon />,
    onlyShowFeatures: ['StopManager', 'AppointmentsReport'],
    header: 'dashboard',
    items: [
      {
        path: '/reports/appointments',
        name: 'Appointments',
        onlyShowFeatures: ['AppointmentsReport'],
        header: 'reports',
      },
      {
        path: '/reports/stops',
        name: 'Alerts',
        onlyShowFeatures: ['StopManager'],
        header: 'reports',
      }
    ],
  },
  {
    path: '/waiting',
    name: 'Virtual Check-in',
    id: 'waitingRoom',
    icon: <HourglassFullIcon />,
    features: ['VWRoom'],
    hideFeatures: ['FrontDesk'],
    header: 'current'
  },
  {
    path: '/front-desk',
    name: 'Front Desk & Alerts',
    icon: <DesktopWindowsIcon />,
    onlyShowFeatures: ['FrontDesk'],
    header: 'current',
  },
  {
    path: '/calendar-v2',
    name: 'Calendar',
    icon: <TodayIcon />,
    features: ['NewCalendar'],
    header: 'current'
  },
  {
    name: 'Automation Center',
    icon: <RobotIcon />,
    onlyShowFeatures: ['AdvancedAutomatedCampaigns'],
    header: 'settings',
    items: [
      {
        path: '/automations',
        name: 'Automations',
        onlyShowFeatures: ['Automations'],
        header: 'automations'
      },
      {
        path: '/automation-messages',
        name: 'Messages',
        header: 'messaging',
        onlyShowFeatures: ['AdvancedAutomatedCampaigns']
      },
    ],
  },
  {
    path: '/schedule',
    name: 'Schedule',
    icon: <ViewColumnIcon />,
    features: ['NPPortal', 'NPPortal2', 'SkedApp'],
    header: 'settings'
  },
  {
    path: '/settings/app',
    name: 'App',
    icon: <PhoneIphoneIcon />,
    features: ['SkedApp'],
    header: 'settings',
  },
  {
    path: '/clients',
    name: 'Clients',
    icon: <ContactsIcon />,
    header: 'current'
  },
  {
    path: '/professionals',
    name: 'Appointment Types',
    icon: <PeopleIcon />,
    header: 'settings'
  },
  {
    path: '/office-hours',
    name: 'Office Hours',
    icon: <ScheduleIcon />,
    features: ['NewCalendar', 'NPPortal'],
    header: 'settings'
  },
  {
    path: '/special-office-hours',
    name: 'Special Office Hours',
    icon: <ScheduleIcon />,
    features: ['NPPortal', 'Autoresponder', 'NPPortal2'],
    header: 'current'
  },
  {
    name: 'Intake Forms',
    icon: <DescriptionIcon />,
    header: 'current',
    onlyShowFeatures: ['Forms'],
    items: [
      {
        path: '/forms-settings',
        name: 'Form Builder',
      },
      {
        path: '/forms-submissions',
        name: 'Submissions',
      }
    ],
  },
  {
    path: '/help',
    name: 'Help',
    icon: <HelpIcon />,
    header: 'settings',
  },
  {
    path: '/inbox',
    name: 'Clients SMS Inbox',
    id: 'inbox',
    icon: <InboxIcon />,
    header: 'messaging',
    badge: 10
  },
  {
    path: '/sms-leads-inbox',
    name: 'Leads SMS Inbox',
    id: 'leadsInbox',
    icon: <InboxIcon />,
    header: 'messaging',
    onlyShowFeatures: ['Leads'],
    badge: 10
  },
  {
    path: '/messages',
    name: 'One-Time Messages',
    icon: <MessageIcon />,
    header: 'messaging',
    features: ['OneTimeMessages']
  },
  {
    name: 'Automated Messages',
    icon: <LoopIcon />,
    header: 'messaging',
    items: [
      {
        path: '/reminder-messages',
        name: 'Reminders',
        icon: <NotificationsIcon />,
        header: 'messaging'
      },
      {
        path: '/birthday-messages',
        name: 'Birthday Messages',
        icon: <CakeIcon />,
        header: 'messaging',
        features: ['Birthday']
      },
      {
        path: '/reactivation-messages',
        name: 'Reactivation',
        icon: <FlashOnIcon />,
        header: 'messaging',
        features: ['ReactivationCampaigns']
      },
      {
        path: '/change-messages',
        name: 'Rapid Messages',
        header: 'messaging',
        onlyShowFeatures: ['ApptChangeMessages']
      },
      {
        path: '/smart-reply/list',
        name: 'Smart Replies',
        icon: <CakeIcon />,
        header: 'messaging',
        features: ['SmartReply']
      },
      {
        path: '/time-based/list',
        name: 'Time-Based Reminders',
        icon: <CakeIcon />,
        header: 'messaging',
        onlyShowFeatures: ['AutomationBasedMessages']
      },
      {
        path: '/autoresponder-messages',
        name: 'Auto-Responder',
        header: 'messaging',
        onlyShowFeatures: ['Autoresponder']
      },
    ]
  },
  {
    path: '/leads',
    name: 'Leads',
    icon: <LeadsIcon />,
    onlyShowFeatures: ['Leads'],
    header: 'marketing',
    isNew: true
  },
  {
    path: '/tags',
    name: 'Tags', //'Client Tags',
    icon: <LabelIcon />,
    features: ['Campaigns'],
    // hideFeatures: ['BasicAutomatedCampaigns', 'AdvancedAutomatedCampaigns'],
    header: 'marketing'
  },
  {
    path: '/campaigns',
    name: 'Campaigns',
    icon: <CampaignIcon />,
    onlyShowFeatures: ['BasicAutomatedCampaigns', 'AdvancedAutomatedCampaigns'],
    header: 'marketing'
  },
  {
    path: '/reviews',
    name: 'Reviews',
    icon: <RateReviewIcon />,
    features: ['Reviews'],
    header: 'marketing',
    id: 'reviews',
  },
  {
    path: '/office-events',
    name: 'Office Events',
    icon: <LiveTvIcon />,
    features: ['SkedApp'],
    header: 'marketing'
  },
  {
    path: 'settings/new-patient-portal',
    name: 'New Patient Portal',
    icon: <WebIcon />,
    features: ['NPPortal2'],
    header: 'marketing',
  },
];

const activeColor = 'rgba(0, 0, 0, 0.15)';

//const backgroundColor = 'rgb(35, 48, 68)';
const backgroundColor = '#008BCF';

const width = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      width,
      flexShrink: 0,
    },
    '@media print': {
      display: 'none'
    },
  },
  list: {
    paddingBottom: 44,
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width,
    zIndex: theme.zIndex.drawer + 11,
    backgroundColor,
    color: 'rgb(238, 238, 238)',
    height: 'calc(100% - 55px)',
    scrollbarWidth: 'none',
    '-ms-overflow-style': 'none',
    top: '55px',
    borderRight: 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    '& a': {
      outline: 'none',
    },
  },
  drawerRootMobile: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  drawerPaperMobile: {
    width,
    backgroundColor,
    color: 'rgb(238, 238, 238)',
    height: '100%',
    scrollbarWidth: 'none',
    top: '0px',
    borderRight: 'none'
  },
  listSubheaderRoot: {
    color: 'rgb(238, 238, 238)',
    backgroundColor,
    paddingTop: '14px',
    paddingBottom: '0px',
    background: backgroundColor
  },
  listSubheaderTypography: {
    fontSize: '13px',
    fontWeight: 500,
    lineHeight: 1.5,
  },
  listItem: {
    outline: 'none',
    '& svg': {
      color: 'rgb(255, 255, 255, 0.93)',
      fontSize: '20px',
      width: '20px',
      height: '20px',
      opacity: '0.5'
    },
    '& span': {
      color: 'rgb(255, 255, 255, 0.93) !important',
      fontSize: '13px !important'
    },
    '&.Mui-selected': {
      backgroundColor: activeColor,
      textDecoration: 'none',
    },
    '&.Mui-selected:hover': {
      backgroundColor: activeColor
    }
  },
  listItemDisabled: {
    '& svg': {
      color: 'rgb(255, 255, 255, 0.3)',
    },
    '& span': {
      color: 'rgb(255, 255, 255, 0.3) !important',
    },
  },
  logoContainer: {
    position: 'fixed',
    width,
    background: backgroundColor,
    minHeight: '54px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)'
  },
  logoContainerMobile: {
    width,
    background: backgroundColor,
    minHeight: '54px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)'
  },
  newLabel: {
    backgroundColor: theme.palette.warning.main,
    borderRadius: '5px',
    paddingLeft: '2px',
    paddingRight: '2px',
    marginLeft: '5px',
    fontSize: '11px'
  },
  betaLabel: {
    backgroundColor: '#4caf50',
    borderRadius: '5px',
    paddingLeft: '2px',
    paddingRight: '2px',
    marginLeft: '5px',
    fontSize: '11px'
  },
  chipRoot: {
    borderRadius: '6px',
    backgroundColor: theme.palette.error.main,
    ' & span': {
      color: '#FFF',
      fontSize: '11px'
    }
  },
  hideButton: {
    // position: 'absolute !important',
    // top: '7.5px',
    position: 'absolute',
    right: '-20px',
    backgroundColor: 'rgba(0,0,0,0.2) !important',
    color: 'white !important',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.3)',
    }
  },
  sparkLogo: {
    height: '19px !important',
    width: 'auto !important',
    marginTop: 4,
    opacity: '0.8 !important',
  },
  itemIcon: {
    minWidth: 36,

    '& svg': {
      width: 23,
      height: 23,
      opacity: '0.8',
      scale: 0.9,
    },
  },
  sparkContainer: {
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    width,
    height: 44,
    bottom: 0,
    zIndex: 1000,
    backgroundColor,
    borderTop: `1px solid ${theme.palette.drawer.border}`,

    '&:hover': {
      backgroundColor: '#0486c7 !important'
    },
    '&.Mui-selected': {
      backgroundColor: '#0774a9 !important',
      textDecoration: 'none',
    },
    '&.Mui-selected:hover': {
      backgroundColor: '#0774a9 !important'
    }
  }
}));


const nestNavItems = (items: NavItem[]) => headers.map(h => {
  h.items = items.filter(({ header }) => h.id === header);
  return h;
});

const emptyArray: NavItem[] = [];
const emptyString: string[] = [];

const filterByFeatures = (features: string[]) => (items: NavItem[] = emptyArray): NavItem[] => {
  const isLead = features.find(feat => feat === 'Leads');
  return items.map(i => {
    const name = !isLead && i.id === 'inbox' ? 'SMS Inbox' : i.name;
    const matches = intersection(i.features || emptyString, features);
    return {
      ...i,
      name,
      isEnabled: !i.features ? true : matches.length > 0,
      items: filterByFeatures(features)(i.items)
    };
  }).filter(i => {
    if (!i.onlyShowFeatures) {
      return true;
    }
    const onlyMatch = intersection(i.onlyShowFeatures, features);
    console.log(onlyMatch, i);
    return onlyMatch.length === i.onlyShowFeatures.length;
  }).filter(i => {
    if (!i.hideFeatures) {
      return true;
    }
    const hideMatch = intersection(i.hideFeatures, features);
    return hideMatch.length === 0;
  });
};


const setActiveStatus = (location: Location) => (items = emptyArray): NavItem[] => {
  return items.map(i => {
    const index = location.pathname.indexOf(i.path);
    return merge(i, {
      isActive: index === 0,
      items: setActiveStatus(location)(i.items)
    });
  });
};

type BadgeType = { inbox: number; reviews: number, leadsInbox: number }
type BadgeKeys = keyof BadgeType;

const setBadges = (badges: BadgeType) => (items: NavItem[]) => {
  return items.map(i => {
    const badge = badges[i.id as BadgeKeys] || 0;
    return assoc('badge', badge, i);
  });
};

const filterHeaders = (headers: Header[]) => headers.filter(({ items }) => items.length > 0);

interface LinkProps extends React.HTMLAttributes<Element> {
  children?: ReactElement[] | ReactElement;
  href: string;
  selected?: boolean;
}

function ListItemLink({ href, ...props }: LinkProps) {
  if (href) {
    return <ListItem button href={'#' + href} component="a" {...props} />;
  }
  return <ListItem button {...props} />;
}

const noop = () => {
  // pass
};

type SKEDDrawerProps = {
  location: Location;
  mobileOpen: boolean;
  desktopOpen: boolean;
  handleDrawerToggle: () => void;
  setDesktopOpen: (value: boolean) => void;
}

const SKEDDrawer = ({
  location,
  mobileOpen,
  desktopOpen,
  handleDrawerToggle,
  setDesktopOpen
}: SKEDDrawerProps) => {
  const classes = useStyles();
  // const theme = useTheme();


  const { features, badges } = useSelector((state) => ({
    features: pathOr([], ['login', 'features'], state),
    badges: {
      // Make sure the keys here match the id in navItems
      // See setBadges function for why
      inbox: pathOr(0, ['messageThread', 'unread'])(state),
      leadsInbox: pathOr(0, ['messageThread', 'leadsUnread'])(state),
      reviews: pathOr(0, ['reviews', 'newReviews'])(state)
    }
  }));

  const SparkSKEDSignUp = includes('SparkSKEDSignUp', features);
  const sparkItemLink = SparkSKEDSignUp ? '/sign-up' : '/spark-webinar';
  const hideSparkItem = includes('Spark', features) && SparkSKEDSignUp;

  const nestedNavItems = pipe(
    filterByFeatures(features),
    setActiveStatus(location),
    setBadges(badges),
    nestNavItems,
    filterHeaders
  )(navItems);

  type keys = keyof NavItem;

  type OpenType = { [key in keys]?: boolean };

  const [open, setOpen] = React.useState<OpenType>(pipe(
    map(prop('items')),
    flatten,
    filter(pipe(prop('items'), isEmpty, not)),
    map(({ name }) => [name, false]),
    (props: [string, boolean][]) => fromPairs(props),
  )(nestedNavItems));

  const handleClick = (name: keys) => () => {
    setOpen({
      ...open,
      [name]: !open[name],
    });
  };

  const getLowerCase = (text: string) => {
    return text ? text.toLowerCase().replace(/ /g, '-') : '';
  };

  const getClassTestName = (name: string, subName?: string) => {
    if (subName) {
      return `sked-test-sidenav-${getLowerCase(subName)}-${getLowerCase(name)}`;
    }
    return `sked-test-sidenav-${getLowerCase(name)}`;
  };

  const drawer = (
    <List className={hideSparkItem ? '' : classes.list}>
      {nestedNavItems.map(({ items, display }) => [
        <ListSubheader
          key={display}
          style={{ background: backgroundColor, color: 'rgb(238, 238, 238)' }}
          classes={{ root: classes.listSubheaderRoot }}>
          <Typography
            classes={{ root: classes.listSubheaderTypography }}
            variant='body1'
            style={{
              fontSize: '13px',
              fontWeight: 500,
              lineHeight: 1.5,
            }}
          >
            {display}
          </Typography>
        </ListSubheader>,
        items.map(({ path, name, icon,
          badge, isActive, isEnabled,
          items, isNew, isBeta }) => {
          const classTestName = getClassTestName(name);
          return (
            [
              <ListItemLink
                key={name}
                href={path}
                onClick={items && items.length > 0 ? handleClick(name as keys) : noop}
                className={classes.listItem + (!isEnabled ? ' ' + classes.listItemDisabled : '') + ' ' + classTestName}
                selected={isActive}
              >
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={name} style={{ flexGrow: 0 }} />
                {isNew ? <div className={classes.newLabel}>New</div> : null}
                {isBeta ? <div className={classes.betaLabel}>Beta</div> : null}
                <div style={{ flexGrow: 1 }}></div>
                {items && items.length > 0 && (open[name as keys] ? <ExpandLess /> : <ExpandMore />)}
                {Number(badge) > 0 && <Chip classes={{ root: classes.chipRoot }} size="small" label={badge} color="default" />}
              </ListItemLink>,
              (items && items.length > 0 ?
                <Collapse key={name + '-collapose'} in={open[name as keys]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {items.map(({ path, name: itemName, isActive, isEnabled }) => (
                      <ListItemLink
                        key={itemName}
                        href={path}
                        className={classes.listItem + (!isEnabled ? ' ' + classes.listItemDisabled : '') + ` ${classTestName}-${getLowerCase(itemName)}`}
                        selected={isActive}
                      >
                        <ListItemText style={{ paddingLeft: '40px' }} primary={itemName} />
                      </ListItemLink>
                    ))}
                  </List>
                </Collapse> : null)
            ]);
        })
      ]
      )}
      {!hideSparkItem && (
        <ListItemLink
          href={sparkItemLink}
          className={`${classes.listItem} ${classes.sparkContainer}`}
          selected={location.pathname.indexOf(sparkItemLink) !== -1}
        >
          <ListItemIcon className={classes.itemIcon}>
            <SparkIcon />
          </ListItemIcon>
          <SparkLogo className={classes.sparkLogo} />
        </ListItemLink>
      )}
    </List>
  );

  const container = window !== undefined ? () => window.document.body : undefined;

  return (
    <nav className={classes.root}>
      {/* Mobile mode */}
      <Hidden smUp implementation="css">
        <Drawer
          container={container}
          PaperProps={{ style: { backgroundColor } }}
          variant="temporary"
          // anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaperMobile,
            root: classes.drawerRootMobile,
            docked: classes.drawerRootMobile
          }}
          ModalProps={{ keepMounted: true }}
        >
          <div className={classes.logoContainerMobile}>
            <img height="30" src={Logo} />
          </div>
          {drawer}
        </Drawer>
      </Hidden>

      {/* Desktop mode */}
      {desktopOpen && <Hidden smDown>
        <div className={classes.logoContainer}>
          <img height="30" src={Logo} />
          <IconButton color='inherit' className={classes.hideButton} onClick={() => setDesktopOpen(false)}>
            <ArrowBackIcon style={{ fontSize: 25 }} />
          </IconButton>
        </div>
        <Drawer
          variant="permanent"
          PaperProps={{ style: { backgroundColor, top: '55px' } }}
          classes={{ paper: classes.drawerPaper }}
          anchor="left">
          {drawer}
        </Drawer>
      </Hidden>
      }
    </nav>
  );
};

export default SKEDDrawer;
