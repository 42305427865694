import * as at from '../../actionTypes';
import * as R from 'ramda';
import api from '../../services/api.js';
import { remoteAction } from '../../services/actionService.js';
import {
  reset,
  getCurrentClient,
  backToClient
} from '../Clients/components/client-dialog/client-dialog.actions.jsx';
import { popup } from '../../services/Popup.js';

export const getFilterData = (stayBusy = false, history) => remoteAction({
  type: at.SKED_REMOTE_GET,
  action: () => api.get('professional').then((pros) => {
    return api.get('appointmentType').then((types) => {
      return {
        pros,
        types,
        stayBusy,
      };
    });
  }).catch((error) => {
    if (error.response) {
      console.error(error);
    } else {
      history && history.push('/offline');
    }
  }),
});

export const selectHour = (hour) => (dispatch) => {
  return dispatch({
    type: at.SELECT_HOUR,
    data: {
      hour,
    }
  });
};

export const selectAppointment = (appointment) => {
  return remoteAction({
    type: at.SKED_REMOTE_APT_GET,
    action: () => api.get(`appointmentType/${appointment.appointmentTypeId}`).then((data) => {
      const aptWithType = R.assoc('type', data.name)(appointment);
      return api.get(`appointment/${appointment.id}/log`)
        .then((data) => {
          return {
            appointment: R.assoc(
              'aptData',
              R.reverse(R.sortBy(R.prop('changedAt'))(data)),
              aptWithType),
          };
        });
    }).catch((error) => {
      popup('Error!', 'Failed to get appointment data.');
      console.error(error);
    }),
  });
};

export const skedPatch = (prop, value) => (dispatch) =>
  dispatch({
    type: at.SKED_PATCH,
    data: {
      [prop]: value,
    }
  });

export const backToSked = () => (dispatch) => {
  dispatch(reset());
  return dispatch({
    type: at.SKED_PATCH,
    data: {
      state: 'SKED',
    }
  });
};

export const selectClientDialog = (client) => (dispatch) =>
  dispatch(getCurrentClient(client));

export const back = () => (dispatch) =>
  dispatch(backToClient());

export const cancelAppointment = ({ id }) => ({
  type: at.SKED_CANCEL_APT,
  data: {
    id
  }
});

export const changeAppointmentStatus = ({ id }, status) => remoteAction({
  type: at.SKED_REMOTE_APT_ARRIVE,
  action: () => api.put(`appointment/${id}`, { status: { [status]: [] }, source: 'Admin' })
    .then(() => {
      return {
        id,
        status,
      };
    })
    .catch((error) => {
      popup('Error!', `Failed to mark appointment as ${status.toLowerCase()}.`);
      console.error(error);
    }),
});


export const editAppointment = (event) => ({
  type: at.SKED_PATCH,
  data: {
    selectedAppointment: event,
    state: 'SKED_EDIT_APT',
  }
});

export const reskedAppointment = (apt) => ({
  type: at.SKED_RESKED_APT,
  data: apt
});
