import api from '../../services/api.js';
import {
  mapObjIndexed, values, pipe, flatten, isNil, omit, uniqBy, prop,
} from 'ramda';
import {
  ListsQueryResponse, AutomationQueryResponse, ClientListHistoryResponse,
  Campaign,
} from './clientlist-types';
import { Automation } from '../Automations/automation-types';

export const addClientList = ({
  name = 'Untitled List',
  description = '',
  isCampaign = false,
  template = false,
}) => {
  const listUrl = template ? 'list/client/template' : 'list/client';
  const campaignUrl = template ? 'list/client/template/campaign' : 'list/client/campaign';
  return api.post(listUrl, { name }).then((list) => {
    if (isCampaign) {
      return api.post(campaignUrl, {
        listId: list.id,
        displayName: name,
        description,
      }).then((campaign) => {
        if (template) {
          const body = {
            path: `tmp.cl.cmp_${campaign.id}`.split('.'),
            resource: {
              ClientList: list.id,
            },
          };
          return api.post('templates/paths', body).then(() => ({
            ...list,
            listId: list.id,
          }));
        }
        return campaign;
      });
    }
    return {
      ...list,
      listId: list.id,
    };
  });
};

interface GetClientListsProps {
  page?: number;
  perPage?: number;
  name: string;
  isCampaign?: boolean;
  template?: boolean;
}

export const getClientLists = ({
  page = 1,
  perPage = 25,
  name,
  isCampaign = false,
  template = false,
}: GetClientListsProps): Promise<ListsQueryResponse> => {
  const url = template ? 'list/client/template/query' : 'list/client/query';
  return api.post(url, {
    page,
    perPage,
    query: {
      name,
      isCampaign,
    },
  }).then((data) => ({
    ...data,
    perPage,
    page,
  }));
};

interface RemoveClientListsProps {
  listIds: number[];
  template?: boolean;
}

export const removeClientLists = ({ listIds, template }: RemoveClientListsProps) => {
  const baseUrl = template ? 'list/client/template/' : 'list/client/';
  return Promise.all(
    listIds.map((id) => api.delete(baseUrl + id))
  );
};

interface GetClientListProps {
  listId: number;
  template?: boolean;
}

export const getClientList = ({ listId, template }: GetClientListProps) => {
  const url = template ? `list/client/template/${listId}` : `list/client/${listId}`;
  return api.get(url);
};

interface ClientQuery {
  firstName?: string;
  lastName?: string;
  phone?: string;
}

interface ClientListClientQuery {
  listId: number;
  client: ClientQuery;
}

interface GetClientListClientsProps {
  page?: number;
  perPage?: number;
  query: ClientListClientQuery;
}

export const getClientListClients = ({
  page = 1,
  perPage = 25,
  query
}: GetClientListClientsProps) => api.post('list/client/clients/query', {
  page,
  perPage,
  query,
}).then((data) => ({
  ...data,
  perPage,
  page,
}));

interface DoClientsProps {
  listId: number;
  clientIds: number[];
}

export const removeClients = ({ listId, clientIds }: DoClientsProps) => Promise.all(
  clientIds.map((id) => api.delete(`list/client/${listId}/clients/${id}`))
);

export const addClients = ({ listId, clientIds }: DoClientsProps) => api.post(`list/client/${listId}/clients`, { clientIds });

interface SaveClientsListProps {
  listId: number;
  name: string;
}

export const saveClientList = ({ listId, name }: SaveClientsListProps) => api.put(`list/client/${listId}`, { name });

interface SaveCampaignProps {
  campaignId: number;
  displayName: string;
  description: string;
  template?: boolean;
}

export const saveCampaign = ({
  campaignId, displayName, description, template,
}: SaveCampaignProps) => {
  const campaignUrl = template ? `list/client/template/campaign/${campaignId}` : `list/client/campaign/${campaignId}`;
  return api.put(campaignUrl, { displayName, description }).then((camp) => {
    const listUrl = template ? `list/client/template/${camp.listId}` : `list/client/${camp.listId}`;
    api.put(listUrl, { name: displayName });
    return camp;
  });
};

interface MessageQuery {
  name: string;
  campaignId: number;
}

interface GetMessagesProps {
  page?: number;
  perPage?: number;
  query: MessageQuery;
  template?: boolean;
}

export const getMessages = ({
  page = 1,
  perPage = 25,
  query,
  template,
}: GetMessagesProps) => {
  if (template) {
    return api.post('message/template/query', {
      page,
      perPage,
      query: {
        ...query,
        path: {
          Exists: {
            isDirectDescendant: `tmp.cl.cmp_${query.campaignId}`.split('.'),
          },
        },
      }
    }).then((data) => ({
      ...data,
      perPage,
      page,
    }));
  }
  return api.post('message/query', {
    page,
    perPage,
    query,
  }).then((data) => ({
    ...data,
    perPage,
    page,
  }));
};

interface GetMessageAutomationsProps {
  msgs: number[];
  campaignId: number;
}
interface MessageIdWithAutomations {
  msgId: number;
  automations: Automation[];
}
export const getMessageAutomations = ({ msgs, campaignId }: GetMessageAutomationsProps): Promise<MessageIdWithAutomations[]> => {
  return Promise.all(msgs.map((id) => {
    const path = `tmp.cl.cmp_${campaignId}.msg_${id}`;
    return api.post('automations/template/query', {
      page: 1,
      perPage: 100,
      query: {
        path: {
          Exists: {
            isDirectDescendant: path.split('.'),
          },
        },
      }
    }).then(({ data }) => ({
      msgId: id,
      automations: data.map((a: Automation) => ({
        ...a,
        isEdit: true,
      })),
    }));
  }));
};

export const getCampaign = (campaignId: number) => {
  if (campaignId) {
    return api.get(`list/client/campaign/${campaignId}`);
  }
  return Promise.resolve(undefined);
};

interface ClientFilterQuery {
  clientListId: number;
}

interface FilterQuery {
  client: ClientFilterQuery;
}

interface TriggerQuery extends ClientFilterQuery {
  trigger: string;
}

interface AutomationQuery {
  enabled: boolean;
  hidden: boolean;
  action: ClientFilterQuery;
  filter: FilterQuery;
  trigger: TriggerQuery;
  campaignId?: number;
}

interface GetAutomationsProps {
  page?: number;
  perPage?: number;
  query: AutomationQuery;
  template?: boolean;
}

export const getAutomations = ({
  page = 1,
  perPage = 25,
  query,
  template,
}: GetAutomationsProps): Promise<AutomationQueryResponse> => {
  const {
    hidden,
    enabled,
  } = query;
  const url = template ? 'automations/template/query' : 'automations/query';
  const queryList = pipe(
    omit(['enabled', 'hidden', 'campaignId']),
    mapObjIndexed((value, key) => {
      const trigger = !query.trigger || key === 'trigger' ? undefined : query.trigger.trigger;
      return api.post(url, {
        page,
        perPage,
        query: {
          hidden,
          enabled: isNil(enabled) ? undefined : enabled,
          trigger: {
            trigger,
          },
          [key]: value,
          path: template ? {
            Exists: {
              isDirectDescendant: `tmp.cl.cmp_${query.campaignId}`.split('.'),
            },
          } : undefined,
        }
      });
    }),
    values,
  )(query);
  return Promise.all(queryList).then((listOfData) => {
    const data: Automation[] = uniqBy(prop('id'), flatten(listOfData.map(({ data }) => data)));
    const totalCount = listOfData.map(({ totalCount }) => totalCount).reduce((a, b) => a + b, 0);
    const totalPages = listOfData.map(({ totalPages }) => totalPages).sort((a, b) => b - a)[0];
    return ({
      data,
      totalPages,
      totalCount,
      page,
      perPage,
    });
  });
};

interface DeleteMessagesProps {
  ids: number[];
  template: boolean;
}

export interface Between {
  start: string;
  end: string;
}

interface HistoryQuery {
  listId?: number;
  clientId?: number;
  between?: Between;
}

interface ClientListHistoryQuery {
  perPage: number;
  page: number;
  query: HistoryQuery;
}

export const getHistory = (data: ClientListHistoryQuery): Promise<ClientListHistoryResponse> => {
  return api.post('list/client/changes/query', data);
};

export const exportHistory = async (data: ClientListHistoryQuery, name = 'export') => {
  const file = await api.post('list/client/changes/query', data, {
    headers: { 'Accept': 'text/csv' },
    responseType: 'blob'
  });

  const href = URL.createObjectURL(file);
  // create "a" HTML element with href to file & click
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', name + '.csv'); //or any other extension
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};

export const deleteMessages = ({ ids, template }: DeleteMessagesProps) => {
  const url = template ? 'message/template' : 'message';
  return Promise.all([
    ids.map((id) => api.delete(`${url}/${id}`)),
  ]);
};

interface CreatePathProps {
  id: number; // either the automation or message id
  campaignId?: number;
  resourceType: 'Automation' | 'Message';
  customPath?: string;
  isTemplate?: boolean;
}

export const createPath = ({
  id, campaignId, resourceType, customPath, isTemplate = true
}: CreatePathProps): Promise<void> => {
  const pathResource = resourceType === 'Automation' ? 'auto_' : 'msg_';
  const path = customPath ? customPath : `tmp.cl.cmp_${campaignId}.${pathResource}${id}`;
  const body = {
    path: path.split('.'),
    resource: {
      [resourceType]: id,
    },
  };
  const urlget = `${isTemplate ? 'templates/' : ''}paths/path/${path}`;
  const urlpost = `${isTemplate ? 'templates/' : ''}paths`;
  return api.get(urlget).catch((e) => {
    if (e.response.status === 404) {
      return api.post(urlpost, body);
    }
    return e;
  });
};

interface GetSparkProps {
  campaignPath: string;
}

export const getSparkCampaign = ({ campaignPath }: GetSparkProps): Promise<Campaign> => {
  return api.get(`paths/path/${campaignPath}`).then((data) => {
    return getClientList({
      listId: data.resource.ClientList.id,
    });
  });
};

export const optoutClient = ({ clientId, listId }: { clientId: number, listId: number }) => {
  return api.delete(`list/client/optout/${listId}/clients/${clientId}`, { data: {} });
};
