import React, { ComponentProps } from 'react';

const LightningBoltIcon = ({ color, className, ...props }: ComponentProps<'svg'>) => {
  return (
    <svg className={className} {...props} id="Group_17089" data-name="Group 17089" xmlns="http://www.w3.org/2000/svg" width="10.846" height="18.692" viewBox="0 0 10.846 18.692">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_13202" data-name="Rectangle 13202" width="10.845" height="18.692" transform="translate(0 0)" fill={color || '#fff'}/>
        </clipPath>
      </defs>
      <g id="Group_16990" data-name="Group 16990" transform="translate(0 0)" clipPath="url(#clip-path)">
        <path id="Path_9479" data-name="Path 9479" d="M4.551,10.534H.1L0,10.4,7.451,0l.218.09c-.451,2.658-.9,5.316-1.37,8.07h4.48l.065.143L3.4,18.692l-.217-.085,1.368-8.073" transform="translate(0 0)" fill={color || '#fff'}/>
      </g>
    </svg>
  );
};

export default LightningBoltIcon;
