import React, { ErrorInfo } from 'react';
import {
  includes,
} from 'ramda';
import {
  Paper,
} from '@mui/material';
import Logo from '../logo-blue.png';
import hb from 'honeybadger-js';

type ErrorBoundaryProps = {
  children: Element
}
type ErrorBoundaryState = {
  hasError: boolean;
  type: string;
}

export default class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      type: null,
    };
  }

  static getDerivedStateFromError(error: Error) {
    const isChunkLoadingError = includes('chunk', error.message);
    return {
      hasError: true,
      type: isChunkLoadingError ? 'chunk' : 'error',
    };
  }
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const isChunkLoadingError = includes('chunk', error.message);
    console.log(error.message);
    if (isChunkLoadingError) {
      window.location.reload();
    } else {
      console.log('Error boundary: ', error, errorInfo);
      hb.notify(error);
    }
  }
  render() {
    if (this.state.hasError && this.state.type === 'error') {
      return (
        <div style={{
          display: 'flex',
          maxWidth: '520px',
          margin: '0px auto',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100%'
        }}>
          <Paper style={{
            width: '100%',
            padding: '40px'
          }}>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center'
            }}>
              <img height="30" src={Logo} />
            </div>
            <div>
              <h2>Oops... Something went wrong.</h2>
              <p>
                Let us know what happened by using the chat or emailing <a href="mailto:info@sked.life">info@sked.life</a>. Otherwise, give this page a refresh to get back into SKED.
              </p>
            </div>

          </Paper>
        </div>
      );
    } else if (this.state.hasError && this.state.type === 'chunk') {
      return (
        <p>There are been an update to the SKED Admin. Auto-reloading...</p>
      );
    }
    return this.props.children;
  }
}
