import * as at from '../../actionTypes.js';
import api from '../../services/api.js';
import * as R from 'ramda';

import { remoteAction } from '../../services/actionService.js';
import { popup } from '../../services/Popup.js';

export const patch = (data) => ({
  type: at.PRO_PATCH,
  data
});

export const checkForProfessionals = () => remoteAction({
  type: at.PRO_REMOTE_PROS_GET,
  action: () => api.get('professional')
    .then((professionals) => {
      return {
        professionals,
        busy: false,
      };
    })
    .catch(() => {
      console.log('professionals not found');
    }),
});

export const getProfessionals = (history, ehrSystem, oldLayout = true) => remoteAction({
  type: at.PRO_REMOTE_PROS_GET,
  action: () => api.get('professional')
    .then((professionals) => {
      if (professionals.length === 0 && ehrSystem !== 'None') {
        popup('Warning!', `Could not find any professionals. This means you probably don't have the SKED client installed. To do so, go to the ${oldLayout ? 'menu in the top right' : 'settings menu in bottom left'} and select "SKED Client Installer."`);
        return {
          busy: false,
        };
      }
      const fallbacks = R.map(({ id }) => api.get(`professional/fallback/${id}`))(professionals);
      return Promise.all(fallbacks)
        .then((fallbacks) => {
          return api.get('appointmentType')
            .then((types) => {
              const notHiddenPro = R.pipe(
                R.filter((pro) => R.isNil(pro.isHidden) ? false : !pro.isHidden),
                R.ifElse(R.isEmpty, R.always([]), R.head)
              )(professionals);
              return {
                professionals: R.sort(R.ascend(R.prop('lastName')), professionals),
                professional: notHiddenPro,
                types: R.sortBy(R.prop('internalName'))(types),
                busy: false,
                setShowHiddenOn: R.isEmpty(notHiddenPro),
                fallbacks: R.flatten(fallbacks),
              };
            })
            .catch((error) => {
              popup('Error!', 'Failed to get appointment types.');
              console.error(error);
            });
        })
        .catch((error) => {
          popup('Error!', 'Failed to get fallback types.');
          console.error(error);
        });
    })
    .catch((error) => {
      if (error.response) {
        popup('Error!', 'Failed to get professionals.');
        console.error(error);
      } else {
        history && history.push('/offline');
      }
    }),
});


export const selectPro = (professional) => ({
  type: at.PRO_SELECT,
  data: {
    professional
  },
});

export const selectToggleApptType = (typeId) => ({
  type: at.APPT_TYPE_TOGGLE,
  data: {
    typeId
  },
});

export const selectToggleApptTypeAll = () => ({
  type: at.APPT_TYPE_TOGGLE_ALL
});


const getSelectedTypes = state => {
  const selectedTypeIds = state.professionals.selectedTypes;
  const types = state.professionals.types;
  return selectedTypeIds.map(id => types.find(R.propEq('id', id)));
};

const updateType = (id, body) => {
  return api.put(`appointmentType/${id}`, body);
};

const updateSelectedTypes = (types, body) => {
  return types.reduce((promise, t) => {
    return promise.then(() => {
      return updateType(t.id, body);
    });
  }, Promise.resolve()).then(() => {
    return api.get('appointmentType');
  });
};

export const updateBulk = (body) => (dispatch, getStore) => {
  const selectedTypes = getSelectedTypes(getStore());
  console.log('hide selected');
  return dispatch(remoteAction({
    type: at.APPT_TYPE_BULK_UPDATE,
    action: () => updateSelectedTypes(selectedTypes, body)
  }));
};

const bulkCreate = (types) => {
  return api.post('appointmentType', types).then(() => {
    return api.get('appointmentType');
  });
};

export const duplicateSelected = () => (dispatch, getStore) => {
  const selectedTypes = getSelectedTypes(getStore()).map(R.omit(['id']));
  console.log('selected', selectedTypes);
  return dispatch(remoteAction({
    type: at.APPT_TYPE_DUPLICATE,
    action: () => bulkCreate(selectedTypes)
  }));
};

export const toggleOpen = (id, value = true) => ({
  type: at.PRO_TOGGLE_OPEN,
  data: {
    open: value,
    id,
  }
});

export const updateTypeColor = (id, color) => ({
  type: at.PRO_UPDATE_COLOR,
  data: {
    color,
    id,
  }
});

export const edit = (id, prop, value) => (dispatch) => {
  return dispatch({
    type: at.PRO_EDIT,
    data: {
      id,
      [prop]: value,
      edited: true,
    }
  });
};

export const save = (type) => (dispatch) => {
  const body = {
    internalName: type.internalName,
    name: type.name,
    duration: type.duration,
    perBlock: type.perBlock,
    appointmentWidth: type.appointmentWidth,
    canCancel: type.canCancel,
    canChange: type.canChange,
    canSee: type.canSee,
    useMaxPerBlock: type.useMaxPerBlock,
    notAllowedMinutesBeforeClosing: type.notAllowedMinutesBeforeClosing !== null ?
      { 'Set': type.notAllowedMinutesBeforeClosing } :
      { 'Unset': [] },
    partOfCarePlan: type.partOfCarePlan,
    color: R.prop('color')(type) !== null ? {
      hue: Math.round(Number(type.color.hue)),
      saturation: Math.round(Number(type.color.saturation)),
      lightness: Math.round(Number(type.color.lightness)),
    } : null,
    scheduleCutoff: type.scheduleCutoff === null ?
      { 'Unset': [] } :
      {
        'Set': (R.propOr(0, 'days', type.scheduleCutoff) * 24 * 60) +
          (R.propOr(0, 'hours', type.scheduleCutoff) * 60) +
          R.propOr(0, 'minutes', type.scheduleCutoff)
      },
    rescheduleCutoff: type.rescheduleCutoff === null ?
      { 'Unset': [] } :
      {
        'Set': (R.propOr(0, 'days', type.rescheduleCutoff) * 24 * 60) +
          (R.propOr(0, 'hours', type.rescheduleCutoff) * 60) +
          R.propOr(0, 'minutes', type.rescheduleCutoff)
      },
    cancelCutoff: type.cancelCutoff === null ?
      { 'Unset': [] } :
      {
        'Set': (R.propOr(0, 'days', type.cancelCutoff) * 24 * 60) +
          (R.propOr(0, 'hours', type.cancelCutoff) * 60) +
          R.propOr(0, 'minutes', type.cancelCutoff)
      },
  };
  return api.put(`appointmentType/${type.id}`, body)
    .then((newType) => {
      dispatch({
        type: at.PRO_EDIT,
        data: {
          newType,
          snackbar: { body: 'Updated appointment type successfully!', isOpen: true },
          state: undefined,
        },
      });
    });
};

export const savePro = (pro) => (dispatch) => {
  const proUpdate = {
    ...pro,
    locationId: pro.locationId ?
      { Set: pro.locationId }
      :
      { Unset: [] }
  };
  return api.put(`professional/${pro.id}`, proUpdate).then((newPro) => {
    return dispatch({
      type: at.PRO_SAVED,
      data: {
        snackbar: { body: 'Professional updated successfully!', isOpen: true },
        state: undefined,
        editedTypes: [],
        newPro
      }
    });
  });
};

export const disableType = (id) => remoteAction({
  type: at.SKEDTYPE_TYPE_DISABLED,
  action: () => api.put(`appointmentType/${id}`, { disabled: true }),
});

const extraProData = {
  slotWidth: 0,
  isHidden: false,
  nameHidden: false,
};
export const addProfessional = (data) => (dispatch) => {
  return api.post('professional/', R.merge(data, extraProData))
    .then(() => {
      return dispatch(getProfessionals(null, 'None'));
    }).catch((error) => {
      popup('Error!', 'Failed to add professional.');
      console.error(error);
    });
};

export const getTypes = () => remoteAction({
  type: at.PRO_REMOTE_TYPES_GET,
  action: () => api.get('appointmentType')
    .catch((error) => {
      popup('Error!', 'Failed to get appointment types.');
      console.error(error);
    }),
});

export const saveFallback = (d) => remoteAction({
  type: at.PRO_REMOTE_SAVE_FALLBACK,
  action: () => api.post('professional/fallback', d)
    .catch((error) => {
      popup('Error!', 'Failed to save fallback type.');
      console.error(error);
    }),
});

export const deleteFallback = (p) => remoteAction({
  type: at.PRO_REMOTE_DELETE_FALLBACK,
  action: () => api.delete(`professional/fallback/${p}`)
    .catch((error) => {
      popup('Error!', 'Failed to delete fallback type.');
      console.error(error);
    }),
});
