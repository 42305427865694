import * as R from 'ramda';
import * as at from '../../../actionTypes.js';
import {
  format,
  now,
} from '../../../services/joda.js';
import {
  LocalTime,
} from '@js-joda/core';
import { parseTime } from '../../../services/parseTime.service';

const init = {
  original: [],
  group: [],
  form: [],
  editing: undefined,
  isSaving: false,
  isLoading: false,
  error: false,
};

const groupHours = (hours = []) => R.pipe(
  R.sort(R.ascend(R.prop('date'))),
  R.sort(R.ascend(R.prop('name'))),
  R.groupWith((a, b) => a.name === b.name &&
    R.equals(a.hours, b.hours) &&
    R.equals(a.locationId, b.locationId) &&
    a.internalName === b.internalName),
  R.map(group => {
    const { id, name, hours, internalName, isEvent, locationId } = group[0];
    const g = {
      id,
      name,
      hours,
      internalName,
      isEvent,
      group,
      locationId,
    };
    return g;
  })
)(hours);


export default (officeHours = init, action) => {
  switch (action.type) {
    case at.SPECIAL_OFFICE_HOURS_REMOTE_GET:
      if (action.state === 'REQUEST') {
        return R.merge(officeHours, {
          isLoading: true,
        });
      } else if (action.state === 'RESPONSE') {
        const group = groupHours(action.data);
        return R.merge(
          officeHours,
          {
            group,
            original: action.data,
            form: action.data,
            isLoading: false,
          }
        );
      } else if (action.state === 'ERROR') {
        return R.merge(officeHours, {
          error: action.error,
          isLoading: false,
        });
      }
      return officeHours;
    case at.SPECIAL_OFFICE_HOURS_ADD_PUSHED: {
      return R.merge(officeHours, {
        editing: action.data,
        error: false,
      });
    }
    case at.SPECIAL_OFFICE_HOURS_PATCH:
      return R.merge(officeHours, action.data);
    case at.SPECIAL_OFFICE_HOURS_DATE_CHANGED: {
      const lens = R.lensProp('date');
      const date = action.data.date;
      return R.evolve({
        editing: R.set(lens, date),
      }, officeHours);
    }
    case at.SPECIAL_OFFICE_HOURS_DATE_TO_CHANGED: {
      const lens = R.lensProp('dateTo');
      const date = action.data.date;
      return R.evolve({
        editing: R.set(lens, date),
      }, officeHours);
    }
    case at.SPECIAL_OFFICE_HOURS_NAME_CHANGED: {
      const lens = R.lensProp('name');
      const name = action.data.name;
      return R.evolve({
        editing: R.set(lens, name),
      }, officeHours);
    }
    case at.SPECIAL_OFFICE_HOURS_EVENT_CHANGED: {
      const lens = R.lensProp('isEvent');
      const isEvent = action.data.isEvent;
      return R.evolve({
        editing: R.set(lens, isEvent),
      }, officeHours);
    }
    case at.SPECIAL_OFFICE_HOURS_INTERNAL_NAME_CHANGED: {
      const lens = R.lensProp('internalName');
      const internalName = action.data.internalName;
      return R.evolve({
        editing: R.set(lens, internalName),
      }, officeHours);
    }
    case at.SPECIAL_OFFICE_HOURS_LOCATION_CHANGED: {
      const lens = R.lensProp('locationId');
      const locationId = action.data.locationId;
      return R.evolve({
        editing: R.set(lens, locationId),
      }, officeHours);
    }
    case at.SPECIAL_OFFICE_HOURS_EDIT_PUSHED: {
      const fromTime = (open) => ({
        time: open,
        parsed: open,
        inputTime: format(LocalTime.parse(open), 'h:mm a'),
        hour: format(LocalTime.parse(open), 'h'),
        minute: format(LocalTime.parse(open), 'mm'),
        a: format(LocalTime.parse(open), 'a'),
      });
      const editing = R.pipe(
        R.find(R.propEq('id', action.data)),
        R.evolve({
          hours: R.map(
            R.mapObjIndexed(fromTime)),
        }))(officeHours.original);
      return R.merge(officeHours, {
        editing,
      });
    }
    case at.SPECIAL_OFFICE_HOURS_REMOVE_OPEN_PUSHED: {
      const hourslens = R.lensProp('hours');
      const index = action.data.index;
      return R.evolve({
        editing: R.over(
          hourslens,
          R.remove(index, 1)
        )
      }, officeHours);
    }
    case at.SPECIAL_OFFICE_HOURS_SAVE_PUSHED: {
      if (action.state === 'RESPONSE') {
        if (!R.has('message')(action.data)) {
          const orderedHours = R.pipe(
            R.sortBy(
              R.prop('start')),
            R.reverse
          )(action.data);
          return R.merge(officeHours, {
            isLoading: false,
            editing: undefined,
            original: orderedHours,
            form: orderedHours
          });
        } else {
          return R.merge(officeHours, {
            isLoading: false,
          });
        }
      } else if (action.state === 'REQUEST') {
        return R.merge(officeHours, {
          isLoading: true,
        });
      } else if (action.state === 'ERROR') {
        return R.merge(officeHours, {
          // error: action.data,
          isLoading: false,
        });
      }
      return officeHours;
    }
    case at.SPECIAL_OFFICE_HOURS_TIME_CHANGED: {
      const hourslens = R.lensProp('hours');
      const openCloseLens = R.lensProp(action.data.openClose);
      const indexLens = R.lensIndex(action.data.index);
      const changeLens = R.lensProp(action.data.change);
      const preLens = R.compose(
        hourslens,
        indexLens,
        openCloseLens,
      );
      const valueLens = R.compose(
        preLens,
        changeLens
      );
      const value = action.data.value;
      const { parsed, error } = parseTime(value);
      return R.evolve({
        editing: R.compose(
          R.set(valueLens, value),
          R.set(R.compose(preLens, R.lensProp('error')), error),
          R.set(R.compose(preLens, R.lensProp('parsed')), parsed)
        )
      }, officeHours);
    }
    case at.SPECIAL_OFFICE_HOURS_ADD_OPENING_PUSHED: {
      const hourslens = R.lensProp('hours');
      const value = {
        close: { hour: '12', minute: '00', a: 'AM' },
        open: { hour: '12', minute: '00', a: 'AM' },
      };
      return R.evolve({
        editing: action.data.hours ?
          R.set(hourslens, action.data.hours)
          :
          R.over(hourslens, R.append(value)),
      })(officeHours);
    }
    case at.SPECIAL_OFFICE_HOURS_CLOSED_PUSHED: {
      const hourslens = R.lensProp('hours');
      const checked = action.data.checked;
      const value = (checked) ? [] : ([{
        close: { hour: '12', minute: '00', a: 'AM' },
        open: { hour: '12', minute: '00', a: 'AM' },
      }]);
      return R.evolve({
        editing: R.set(hourslens, value),
      })(officeHours);
    }
    case at.SPECIAL_OFFICE_HOURS_CANCEL_PUSHED: {
      return R.merge(officeHours, {
        editing: undefined,
      });
    }
    case at.SPECIAL_OFFICE_HOURS_UPDATE_FORM: {
      const { index, data, i } = action.data;
      return R.evolve({
        form: hours => {
          return R.adjust(
            index,
            R.evolve({
              hours: R.update(i, data),
            }),
            hours);
        }
      }, officeHours);
    }
    case at.SPECIAL_OFFICE_HOURS_UPDATE_FORM_DATE: {
      const { index, date } = action.data;
      return R.evolve({
        form: hours => {
          return R.adjust(
            R.evolve({
              date: () => date,
            }),
            index,
            hours);
        }
      }, officeHours);
    }
    case at.SPECIAL_OFFICE_HOURS_ADD: {
      const { index } = action.data;
      return R.evolve({
        form: hours => {
          return R.adjust(
            index,
            R.evolve({
              hours: R.append({ close: '12:00:00', open: '12:00:00' })
            }),
            hours);
        }
      }, officeHours);
    }
    case at.SPECIAL_OFFICE_HOURS_UPDATE_NAME: {
      const { index, name } = action.data;
      return R.evolve({
        form: hours => {
          return R.adjust(
            index,
            R.evolve({
              name: () => name,
            }),
            hours);
        }
      }, officeHours);
    }
    case at.SPECIAL_OFFICE_HOURS_REMOVE: {
      const { index, i } = action.data;
      return R.evolve({
        form: hours => {
          return R.adjust(
            index,
            R.evolve({
              hours: R.remove(i, 1),
            }),
            hours);
        }
      }, officeHours);
    }
    case at.SPECIAL_OFFICE_HOURS_CANCEL: {
      return R.evolve({
        form: () => officeHours.original,
      }, officeHours);
    }
    case at.SPECIAL_OFFICE_HOURS_NEW_HOURS: {
      const { officeId } = action.data;
      return R.evolve({
        form: R.append({
          scheduleOnOpening: true,
          hours: [
            {
              close: '12:00:00',
              open: '12:00:00'
            }
          ],
          date: format(now('date'), 'yyyy-MM-dd'),
          name: '',
          internalName: '',
          scheduleOnClosing: true,
          officeId,
        }),
      }, officeHours);
    }
    case at.SPECIAL_OFFICE_HOURS_DELETE: {
      const { index } = action.data;
      return R.evolve({
        form: R.remove(index, 1),
      }, officeHours);
    }
    default:
      return officeHours;
  }
};
