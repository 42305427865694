import * as at from '../../actionTypes.js';
import api from '../../services/api.js';
import { remoteAction } from '../../services/actionService.js';
import {
  now,
  formatStandard,
} from '../../services/joda.js';
import { merge, path, split, length } from 'ramda';
import { notification } from '../../services/utilities.js';
import {
  reset,
  getCurrentClient,
  backToClient,
} from '../Clients/components/client-dialog/client-dialog.actions.jsx';


export const getReviews = (body) => remoteAction({
  type: at.REVIEWS_REMOTE_GET,
  action: () => api.post('officeReviewSettings/feedback', body)
    .then((data) => merge(data, { perPage: body.perPage }))
});

export const getSent = (body) => remoteAction({
  type: at.REVIEWS_REMOTE_GET_SENT,
  action: () => api.post('officeReviewSettings/sentTo', body)
    .then((data) => merge(data, { perPage: body.perPage }))
});


export const getReviewSettings = () => (dispatch) =>
  api.get('officeReviewSettings')
    .then((settings) =>
      dispatch({
        type: at.GET_SETTINGS_REVIEWS,
        data: settings,
      }));

export const checkForReviews = () => (dispatch, getStore) => {
  const authed = path(['session', 'access_token'], getStore());
  const admin = path(['login', 'admin'], getStore());
  if (!authed) {
    return;
  }

  return api.post('officeReviewSettings/feedback', { page: 1, perPage: 25, query: [] }).then(({ data }) => {
    const lastRead = path(['settingsReviews', 'lastRead'], getStore());
    const currentReviews = path(['reviews', 'newReviews'], getStore());
    const newReviews = data.filter(({ created }) => created > lastRead);
    const newLength = newReviews.length;
    dispatch({
      type: at.NEW_REVIEWS_GET,
      data: { newReviews: newLength },
    });
    if (!admin && length(split('reviews', window.location.href)) < 2 && newLength > currentReviews && newLength > 0) {
      const reviewsText = newLength > 1 ? 'reviews' : 'review';
      const text = `You have ${newLength} new ${reviewsText}!`;
      notification(text, { tag: newReviews[0].created, }, '/#/reviews/');
    }
    // Disable polling because it's causing per performance issues
    // window.setTimeout(() => dispatch(checkForReviews()), 60e3 * 5);
  }).catch((error) => {
    console.log('error getting reviews', error);
    // window.setTimeout(() => dispatch(checkForReviews()), 60e3 * 5);
  });
};

export const updateLastRead = () => (dispatch) => {
  const newDate = formatStandard(now('tz'));
  dispatch({
    type: at.REVIEWS_PATCH,
    data: { busy: true },
  });
  api.post('officeReviewSettings/lastRead', { lastRead: newDate }).then(() => {
    dispatch({
      type: at.REVIEWS_LAST_READ_UPDATE,
      data: { newReviews: 0, busy: false },
    });
    dispatch({
      type: at.GET_SETTINGS_REVIEWS,
      data: { lastRead: newDate },
    });
  }).catch(() => {
    dispatch({
      type: at.REVIEWS_PATCH,
      data: { busy: false },
    });
  });
};

export const selectClient = (openClient, client) => (dispatch) => {
  dispatch(getCurrentClient(client));
  return openClient();
};

export const selectClientDialog = (client) => (dispatch) =>
  dispatch(getCurrentClient(client));

export const backToReview = (revertState) => (dispatch) => {
  dispatch(reset());
  return revertState();
};

export const back = () => (dispatch) =>
  dispatch(backToClient());
