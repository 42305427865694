import * as R from 'ramda';
import * as at from '../../../../actionTypes.js';

const init = {
  state: 'VIEW',
  installer: {}
};

export default function reducer(installer = init, action) {
  switch (action.type) {
    case at.INSTALLER_PATCH:
      return R.merge(installer, action.data);
    default:
      return installer;
  }
}
