/**
 * CreateCourse ActionCreator
 * @param course
 * @returns {{type: string, course: *}}
 */
import * as at from '../../actionTypes';
import * as actionTypes from './clients.actionTypes';
import api from '../../services/api.js';
import { remoteAction } from '../../services/actionService.js';
import {
  // clientPatch,
  reset,
  getCurrentClient,
  backToClient,
}
  from './components/client-dialog/client-dialog.actions.jsx';
import { popup } from '../../services/Popup.js';
import * as R from 'ramda';
import axios from 'axios';

export const searchPatch = (prop, value) => (dispatch) =>
  dispatch({
    type: at.CLIENTS_SEARCH_PATCH,
    data: {
      [prop]: value,
    }
  });

export const backToList = () => (dispatch) => {
  dispatch(reset());
  return dispatch({
    type: at.CLIENTS_BACK_TO_LIST,
    data: {
      state: 'CLIENT_LIST',
      currentClient: {},
      appointments: [],
      group: [],
    }
  });
};

export const selectClient = (client) => (dispatch) => {
  dispatch(getCurrentClient(client));
  return dispatch({
    type: actionTypes.CLIENTS_SELECT,
    data: {
      busy: false,
      state: 'CLIENT_SELECT',
    }
  });
};

export const patchSearch = (data, search) => (dispatch) => {
  dispatch({
    type: actionTypes.CLIENTS_PATCH,
    data,
  });
  return dispatch(getClients(search));
};

const CancelToken = axios.CancelToken;
let cancel;
export const getClients = (data, history) => {
  let params = data;
  if (data.query?.status?.length === 0) {
    params = {
      ...data,
      query: {
        ...data.query,
        status: undefined
      }
    };
  }
  if (params.query) {
    params.query = {
      ...params.query,
      sortBy: [{
        direction: 'Asc',
        field: 'LastName'
      }, {
        direction: 'Asc',
        field: 'FirstName'
      }],
      isLead: false
    };
  }
  if (cancel)
    cancel('User aborted request.');
  return remoteAction({
    type: at.CLIENTS_REMOTE_GET,
    action: () => api.post('client/query', params, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
      })
    }).then((clients) => {
      return clients;
    }).catch((error) => {
      if (error.response) {
        if (R.pathOr(null, ['query', 'phone'])(data) === undefined && error.response.status)
          popup('Error!', 'Failed to get clients.');
        console.error(error);
      } else if (axios.isCancel(error)) {
        throw error;
      } else {
        history.push('/offline');
      }
    }),
  });
};

export const back = () => (dispatch) =>
  dispatch(backToClient());

export const getTags = () => remoteAction({
  type: at.CLIENTS_REMOTE_GET_TAGS,
  action: () => api.get('client/tag'),
});
