import * as at from '../../actionTypes';
import * as actionTypes from './clients.actionTypes';
import * as R from 'ramda';
import {
  now
} from '../../services/joda.js';

const init = {
  busy: false,
  clients: [],
  state: 'CLIENTS_LIST',
  currentClient: {},
  search: '',
  page: 1,
  perPage: 25,
  searchBy: 'name',
  startDate: now('date'),
  endDate: now('date').plusDays(7),
  totalCount: 0,
  totalPages: 1,
  status: ['Active'],
  tags: [],
  allTags: [],
  tagsSearch: '',
  selectedTags: [],
  allowNotifications: [],
};

export default (clients = init, action) => {
  switch (action.type) {
    case actionTypes.CLIENTS_PATCH:
      return R.merge(clients, action.data);
    case at.CLIENTS_REMOTE_GET:
      if (action.state === 'REQUEST') {
        return R.merge(clients, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        return R.merge(clients, {
          clients: action.data.data,
          totalCount: action.data.totalCount,
          totalPages: action.data.totalPages,
          page: action.data.page,
          busy: false,
        });
      } else if (action.state === 'ERROR') {
        return R.merge(clients, {
          busy: action.isCancel,
        });
      }
      return clients;
    case actionTypes.CLIENTS_SELECT:
      return R.merge(clients, action.data);
    case actionTypes.CLIENTS_VIEW_APT:
      return R.merge(clients, action.data);
    case at.CLIENTS_BACK_TO_LIST:
      return R.merge(clients, action.data);
    case at.CLIENTS_SEARCH_PATCH:
      return R.merge(clients, action.data);
    case actionTypes.CLIENTS_DATE_PATCH:
      return R.merge(clients, action.data);
    case at.CLIENTS_UPDATE_CURRENT:
      return R.evolve({
        clients: R.map((client) => {
          if (client.id === action.data.id) {
            return R.merge(client, action.data);
          }
          return client;
        })
      }, clients);
    case at.CLIENTS_REMOTE_GET_TAGS:
      if (action.state === 'REQUEST') {
        return R.merge(clients, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        const allTags = R.sortBy(R.prop('tag'))(action.data);
        return R.merge(clients, {
          busy: false,
          allTags,
          tags: allTags,
        });
      } else if (action.state === 'ERROR') {
        return R.merge(clients, {
          busy: false,
        });
      }
      return clients;
    default:
      return clients;
  }
};
