import React from 'react';
import { useDispatch } from 'react-redux';
import {
  IconButton,
  Menu,
  MenuItem,
  Divider,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Apartment from '@mui/icons-material/Apartment';
import MeetingRoom from '@mui/icons-material/MeetingRoom';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ListAltIcon from '@mui/icons-material/ListAlt';
import SettingsBackupRestore from '@mui/icons-material/SettingsBackupRestore';
import Sync from '@mui/icons-material/Sync';
import FileCopy from '@mui/icons-material/FileCopy';
import SwapHoriz from '@mui/icons-material/SwapHoriz';
import GroupAdd from '@mui/icons-material/GroupAdd';
import MessageIcon from '@mui/icons-material/Message';
import Banners from '@mui/icons-material/AssignmentLate';
import Email from '@mui/icons-material/Email';
import File from '@mui/icons-material/Description';
import SettingsIcon from '@mui/icons-material/Settings';
import AgenciesIcon from '@mui/icons-material/BusinessSharp';
import RobotIcon from '@mui/icons-material/Toys';
import { Link } from 'react-router-dom';
import { Spacer } from '../PageHeader';
import * as actions from '../../routes/Login/login.actions';
import { useSelector } from '../../reducers';
// import { cond, equals, T } from 'ramda';

import * as R from 'ramda';

import Logo from '../../logo-white.png';

import { useStyles } from './admin-header.styles';

function AdminHeader() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [sideAnchorEl, setSideAnchorEl] = React.useState(null);
  const [type, setType] = React.useState('templates');
  const open = Boolean(anchorEl);

  const agencyAdmin = useSelector(state => state.login.agencyAdmin);
  const agency = useSelector(state => state.login.agency);

  const destroySession = () => dispatch(actions.destroySession());

  const openSideMenu = (type: string) => (target: EventTarget) => {
    setType(type);
    setSideAnchorEl(target);
  };

  const transferMenu = [
    <MenuItem
      key='transfer messages'
      onClick={() => {
        setSideAnchorEl(null);
        setAnchorEl(null);
      }}>
      <Link to="/transfer-messages">
        <ListItemIcon>
          <Email fontSize="large" />
        </ListItemIcon>
        <ListItemText primary='Messages' />
      </Link>
    </MenuItem>,
    <MenuItem
      key='transfer forms'
      onClick={() => {
        setSideAnchorEl(null);
        setAnchorEl(null);
      }}>
      <Link to="/transfer-forms">
        <ListItemIcon>
          <File fontSize="large" />
        </ListItemIcon>
        <ListItemText primary='Forms' />
      </Link>
    </MenuItem >
  ];

  const templatesMenu = [
    <MenuItem
      key='automations-templates'
      onClick={() => {
        setSideAnchorEl(null);
        setAnchorEl(null);
      }}>
      <Link to="/automations-templates">
        <ListItemIcon>
          <RobotIcon fontSize="large" />
        </ListItemIcon>
        <ListItemText primary='Automations' />
      </Link>
    </MenuItem>,
    <MenuItem
      key='campaign-templates'
      onClick={() => {
        setSideAnchorEl(null);
        setAnchorEl(null);
      }}>
      <Link to="/campaign-templates">
        <ListItemIcon>
          <ListAltIcon fontSize="large" />
        </ListItemIcon>
        <ListItemText primary='Campaigns' />
      </Link>
    </MenuItem>,
    <MenuItem
      key='message-templates'
      onClick={() => {
        setSideAnchorEl(null);
        setAnchorEl(null);
      }}>
      <Link to="/templates">
        <ListItemIcon>
          <MessageIcon fontSize="large" />
        </ListItemIcon>
        <ListItemText primary='Message' />
      </Link>
    </MenuItem>
  ];
  return (
    <header className="admin-header">
      <h1>Admin</h1>
      <Spacer />
      <div>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          style={{ color: 'white' }}
          onClick={(e) => setAnchorEl(e.currentTarget)}>
          <MoreVertIcon fontSize='large' />
        </IconButton>
        <Menu
          anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={() => setAnchorEl(null)}>
          {R.ifElse(
            (isAgencyAdmin: boolean) => isAgencyAdmin,
            () => [
              <MenuItem onClick={() => setAnchorEl(null)}>
                <Link to="/settings/select-office">
                  <ListItemIcon>
                    <Apartment fontSize="large" />
                  </ListItemIcon>
                  <ListItemText primary='Offices' />
                </Link>
              </MenuItem>,
              <MenuItem onClick={() => setAnchorEl(null)}>
                <Link to="/profile/settings">
                  <ListItemIcon>
                    <SettingsIcon fontSize="large" />
                  </ListItemIcon>
                  <ListItemText primary='Profile Settings' />
                </Link>
              </MenuItem>,
              <MenuItem onClick={() => setAnchorEl(null)}>
                <Link to="/users">
                  <ListItemIcon>
                    <GroupAdd fontSize="large" />
                  </ListItemIcon>
                  <ListItemText primary='Manage Users' />
                </Link>
              </MenuItem>
            ],
            () => agency ? [
              <MenuItem onClick={() => setAnchorEl(null)}>
                <Link to="/settings/select-office">
                  <ListItemIcon>
                    <Apartment fontSize="large" />
                  </ListItemIcon>
                  <ListItemText primary='Offices' />
                </Link>
              </MenuItem>,
              <MenuItem onClick={() => setAnchorEl(null)}>
                <Link to="/profile/settings">
                  <ListItemIcon>
                    <SettingsIcon fontSize="large" />
                  </ListItemIcon>
                  <ListItemText primary='Profile Settings' />
                </Link>
              </MenuItem>
            ] : [
              <MenuItem onClick={() => setAnchorEl(null)}>
                <Link to="/settings/select-office">
                  <ListItemIcon>
                    <Apartment fontSize="large" />
                  </ListItemIcon>
                  <ListItemText primary='Select Office' />
                </Link>
              </MenuItem>,
              <MenuItem onClick={() => setAnchorEl(null)}>
                <Link to="/profile/settings">
                  <ListItemIcon>
                    <SettingsIcon fontSize="large" />
                  </ListItemIcon>
                  <ListItemText primary='Profile Settings' />
                </Link>
              </MenuItem>,
              <MenuItem onClick={() => setAnchorEl(null)}>
                <Link to="/affiliates">
                  <ListItemIcon>
                    <GroupAdd fontSize="large" />
                  </ListItemIcon>
                  <ListItemText primary='Affiliate Groups' />
                </Link>
              </MenuItem>,
              <MenuItem onClick={() => setAnchorEl(null)}>
                <Link to="/agencies">
                  <ListItemIcon>
                    <AgenciesIcon fontSize="large" />
                  </ListItemIcon>
                  <ListItemText primary='Agencies' />
                </Link>
              </MenuItem>,
              <MenuItem onClick={() => setAnchorEl(null)}>
                <Link to="/check-unsynced">
                  <ListItemIcon>
                    <Sync fontSize="large" />
                  </ListItemIcon>
                  <ListItemText primary='Check Unsynced' />
                </Link>
              </MenuItem>,
              <MenuItem onClick={() => setAnchorEl(null)}>
                <Link to="/reset-user">
                  <ListItemIcon>
                    <SettingsBackupRestore fontSize="large" />
                  </ListItemIcon>
                  <ListItemText primary='Reset Options' />
                </Link>
              </MenuItem>,
              <MenuItem onClick={(event) => openSideMenu('templates')(event.target)}>
                <ListItemIcon>
                  <FileCopy fontSize="large" />
                </ListItemIcon>
                <ListItemText primary='Templates' />
                <ListItemIcon style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}>
                  <ArrowRightIcon fontSize="large" />
                </ListItemIcon>
              </MenuItem>,
              <MenuItem onClick={(event) => openSideMenu('transfer')(event.target)}>
                <ListItemIcon>
                  <SwapHoriz fontSize="large" />
                </ListItemIcon>
                <ListItemText primary='Transfer' />
                <ListItemIcon style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}>
                  <ArrowRightIcon fontSize="large" />
                </ListItemIcon>
              </MenuItem>,
              <MenuItem onClick={() => setAnchorEl(null)}>
                <Link to="/banners">
                  <ListItemIcon>
                    <Banners fontSize="large" />
                  </ListItemIcon>
                  <ListItemText primary='Banners' />
                </Link>
              </MenuItem>,
            ]
          )(agencyAdmin)}
          <Divider />
          <MenuItem onClick={destroySession}>
            <Link to="/login">
              <ListItemIcon>
                <MeetingRoom fontSize="large" />
              </ListItemIcon>
              <ListItemText primary='Sign Out' />
            </Link>
          </MenuItem>
        </Menu>
        <Menu
          style={{ width: 'auto' }}
          anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          id="swap-menu"
          anchorEl={sideAnchorEl}
          open={Boolean(sideAnchorEl)}
          onClose={() => setSideAnchorEl(null)}>
          {R.cond([
            [R.equals('templates'), () => templatesMenu],
            [R.equals('transfer'), () => transferMenu],
            [R.T, () => [] as JSX.Element[]]
          ])(type)}
        </Menu>
      </div>
      {(agencyAdmin || agency) && (
        <div className={classes.logContainer}>
          <img
            src={Logo}
            className={classes.image}
          />
        </div>
      )}
    </header>
  );
}

export default AdminHeader;
