import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { includes, pathOr, without } from 'ramda';
import { registerSW } from './utilities.js';
import { closeSocket, initSocket } from './websockets';
import { useSocket } from './websocket.hook';
import {
  checkForMessages,
  initMessageListener,
  closeMessageListener,
} from '../routes/Messages/routes/MessagesThread/messages-thread.actions.jsx';
import {
  initFrontDeskEvents,
  closeFrontDeskEvents,
  initFrontDeskListener,
  initStopMangerListener,
} from '../routes/waiting/waiting.service.js';
import { getReviewSettings, checkForReviews } from '../routes/Reviews/reviews.actions.jsx';

export function useSKEDAdmin() {
  const dispatch = useDispatch();
  const socket = useSocket();

  const { features, isAdmin } = useSelector((state) => ({
    isAdmin: pathOr([], ['login', 'admin'], state),
    features: pathOr([], ['login', 'features'], state)
  }));

  const appt = includes('RealTimeApptUpdates', features) && 'Appointment';
  const msgThd = includes('WebsocketsMessagingWaiting', features) && 'MessageThread';
  const wait = includes('WebsocketsMessagingWaiting', features) && 'WaitingRoom';
  const frontdesk = includes('StopManager', features) && 'Stops';
  const hasFrontDeskFeature = includes('FrontDesk', features);
  const pdfs = includes('FormsPdf', features) && 'FormPdfCreated';
  const blanks = includes('FormsPdf', features) && 'FormBlankPdfCreated';
  const subs = without([false], [appt, msgThd, wait, frontdesk, pdfs, blanks]);

  const initListeners = (sock) => {
    if (dispatch && features) {
      if (wait) {
        initFrontDeskListener(sock, hasFrontDeskFeature);
      } else {
        initFrontDeskEvents(hasFrontDeskFeature);
      }
      if (frontdesk) {
        initStopMangerListener(socket);
      }
      if (!isAdmin) {
        if (msgThd) {
          console.log('initializing message listener...', sock);
          dispatch(initMessageListener(sock));
        } else {
          dispatch(checkForMessages());
        }
        if (includes('Reviews', features)) {
          dispatch(getReviewSettings())
            .then(() => {
              dispatch(checkForReviews());
            })
            .catch(() => {
              console.log('error getting review setings');
            });
        }
      }
    }
  };

  useEffect(() => {
    Promise.resolve().then(() => {
      try {
        return registerSW();
      } catch (e) {
        console.error('failed to register sw');
      }
    }).then(() => {
      dispatch(initSocket(subs)).then((s) => {
        initListeners(s);
      });
    });
    return () => {
      closeMessageListener();
      closeFrontDeskEvents();
      closeSocket();
    };
  }, [dispatch, isAdmin, features]);

  useEffect(() => {
    initListeners(socket);
  }, [socket]);
}
