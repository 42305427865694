import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Typography, Dialog, Grid, IconButton, Button } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import BoldIcon from '../../icons/LightningBolt.icon';
import * as loginActions from '../../routes/Login/login.actions';

import { useSelector } from '../../reducers';

import { useStyles } from './newUiPopup.styles';

let timeout: NodeJS.Timeout = null;
const key = 'sked-new-ui-last-cancelled';

const NewUiPopup = () => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();

  const office = useSelector(state => state.login.office);
  const oldLayout = useSelector(state => state.login.oldLayout);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    localStorage.setItem(key, new Date().getTime().toString());
    handleClose();
  };

  const handleSave = () => {
    dispatch(loginActions.changeLayout({ oldLayout: false }));
    handleClose();
  };

  useEffect(() => {
    clearTimeout(timeout);
    if (!office?.id || !oldLayout) {
      return;
    }
    const lastCancelledTimestamp = localStorage.getItem(key);
    if (lastCancelledTimestamp === 'done') {
      return;
    }
    if (!lastCancelledTimestamp || new Date(Number(lastCancelledTimestamp) + (4 * 24 * 60 * 60 * 1000)) < new Date()) {
      timeout = setTimeout(() => {
        setOpen(true);
      }, 30 * 60 * 1000);
    }
  }, [office?.id, oldLayout]);

  useEffect(() => {
    if (!oldLayout) {
      localStorage.setItem(key, 'done');
      clearTimeout(timeout);
    }
  }, [oldLayout]);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleCancel}
      classes={{ paper: classes.paper }}
      PaperProps={{ style: { maxWidth: 650, width: '100%' } }}
    >
      <Grid padding={2} position="relative" display="flex" alignItems="center" flexWrap="wrap">
        <IconButton
          aria-label="close"
          onClick={handleCancel}
          className={`${classes.closeButton} sked-test-new-ui-popup-button-close`}>
          <CloseIcon className={classes.icon} />
        </IconButton>
        <Grid className={classes.column} display="flex" alignItems="center" justifyContent="center">
          <img src="/samples/desktop-computer-mockup-front-desk.png" />
        </Grid>
        <Grid className={classes.column} >
          <Typography className={classes.title}>Fresh New Look</Typography>
          <Typography className={classes.description}>
            We're thrilled to unveil our new design! These updates makes SKED more intuitive and enjoyable than ever before.
          </Typography>
          <Typography className={classes.text}>
            Don't worry, you'll be able to go back to the old design if you want.
          </Typography>
          <Button onClick={handleSave} color='primary' variant='contained' className={classes.button}>
            <BoldIcon />
            Use new look
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default NewUiPopup;
