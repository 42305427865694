import React from 'react';
import {
  TextField as MuiTextField, TextFieldProps,
  Switch as MuiSwitch, SwitchProps, Select as MuiSelect,
  SelectProps, Autocomplete as MuiAutocomplete, AutocompleteProps,
  Chip as MuiChip, ChipProps, Checkbox as MuiCheckbox, CheckboxProps,
} from '@mui/material';
import {
  createTheme, ThemeProvider,
} from '@mui/material/styles';
import ClearIcon from '@mui/icons-material/Clear';
import CheckedIcon from '../icons/Checked.icon';
import UncheckedIcon from '../icons/Unchecked.icon';
import IndeterminateIcon from '../icons/Indeterminate.icon';

const textFieldTheme = () =>
  createTheme({
    typography: {
      fontFamily: [
        'Poppins',
        'Roboto',
        'Arial',
      ].join(','),
    },
    components: {
      MuiInputLabel: {
        styleOverrides: {
          root: {
            '&.Mui-focused': {
              transform: 'translate(14px, -7px) scale(0.80)',
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            padding: '5px 5px !important',
          },
        },
      },
    }
  });


export const TextField = (props: TextFieldProps) => {
  return (
    <ThemeProvider theme={textFieldTheme()}>
      <MuiTextField
        variant='outlined'
        {...props}
        InputProps={{
          style: {
            padding: '5px 5px',
            width: '100%',
            minHeight: '35px',
            //            height: '35px',
          },
          ...props.InputProps,
        }}
        sx={{
          '& .MuiInputLabel-root':
            !props.InputLabelProps?.shrink && props.value === '' ? {
              transform: 'translate(14px, 8px) scale(1)',
            } : {
              transform: props.InputLabelProps?.shrink ?
                'translate(14px, -7px) scale(0.80)' : 'unset',
            },
          '& .MuiInputBase-input': {
            padding: '16.5px 4px',
            fontSize: 14,
            height: props.minRows === 1 ?
              `${navigator.userAgent.includes('Firefox') ? '17px' : '21px'} !important` : undefined,
          },
          '& .MuiInputAdornment-root': {
            marginRight: '0px',
            marginLeft: '3px',
          },
          '& .MuiOutlinedInput-root': {
            '& .MuiInput-underline:after': {
              borderBottomColor: '#37AEFD',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#37AEFD',
            },
            //            '& fieldset': {
            //              borderColor: '#37AEFD',
            //            },
            '&:hover fieldset': {
              borderColor: '#37AEFD',
            },
          },
          ...props.sx,
        }}
      />
    </ThemeProvider>
  );
};

export const Switch = (props: SwitchProps) => {
  return (
    <MuiSwitch
      {...props}
      sx={{
        padding: '8px',
        '& .MuiSwitch-switchBase': {
          color: 'gray',
          '&.Mui-checked': {
            '& .MuiSwitch-thumb': {
              width: 12,
              height: 12,
              border: 'white 2px solid',
              marginLeft: 'unset',
              //              backgroundColor: '#008BCF',
            },
            '+ .MuiSwitch-track': {
              border: '#008BCF 2px solid',
              backgroundColor: '#008BCF',
              opacity: 1,
            },
          },
        },
        '& .MuiSwitch-track': {
          borderRadius: 22 / 2,
          border: 'gray 2px solid',
          backgroundColor: 'white',
          '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
          },
          '&:before': {
            left: 12,
          },
          '&:after': {
            right: 12,
          },
        },
        '& .MuiSwitch-thumb': {
          boxShadow: 'none',
          width: 16,
          height: 16,
          marginTop: '4px',
          marginLeft: '3px',
        },
        ...props.sx,
      }}
    />
  );
};

export const Select = (props: SelectProps) => {
  return (
    <MuiSelect
      variant='outlined'
      {...props}
      style={{
        height: '32px',
        ...props.style
      }}
    />
  );
};

const autocompleteTheme = () =>
  createTheme({
    typography: {
      fontFamily: [
        'Poppins',
        'Roboto',
        'Arial',
      ].join(','),
    },
    palette: {
      secondary: {
        main: '#4D4D4D',
      },
    },
    components: {
      MuiChip: {
        styleOverrides: {
          root: {
            backgroundColor: '#EEF5FF',
            fontSize: '14px',
            borderRadius: '6px',
            color: '#008BCF',
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            fontSize: 'inherit',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            height: '35px',
            paddingTop: '5px !important',
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            marginBottom: '15px',
          },
          tag: {
            margin: 'unset',
            marginRight: '2px',
          },
          input: {
            fontSize: '14px',
            fontWeight: 500,
            paddingTop: 5,
            paddingBottom: 5,
          },
          option: {
            fontSize: '13px',
          },
          groupLabel: {
            fontSize: '13px',
          },
          endAdornment: {
            position: 'absolute',
            top: 'unset',
            fontSize: '25px',
          },
          popupIndicator: {
            fontSize: '25px',
          },
          clearIndicator: {
            fontSize: '14px',
          },
          listbox: {
            option: {
              '&[aria-checked=true]': {
                backgroundColor: 'blue',
              },
            },
          },
        },
      },
    },
  });

export const Autocomplete = (props: AutocompleteProps<any, boolean | undefined, boolean | undefined, boolean | undefined>) => {
  return (
    <ThemeProvider theme={autocompleteTheme()}>
      <MuiAutocomplete
        size='small'
        {...props}
        style={{
          minHeight: '35px',
          ...props.style
        }}
        ChipProps={{
          deleteIcon: (
            <ClearIcon style={{ color: '#008BCF' }} />
          ),
        }}
      />
    </ThemeProvider>
  );
};

const chipTheme = () =>
  createTheme({
    typography: {
      fontFamily: [
        'Poppins',
        'Roboto',
        'Arial',
      ].join(','),
    },
    components: {
      MuiChip: {
        styleOverrides: {
          root: {
            backgroundColor: '#EEF5FF',
            fontSize: '14px',
            borderRadius: '6px',
            color: '#008BCF',
          },
        },
      },
    }
  });

export const Chip = (props: ChipProps) => {
  return (
    <ThemeProvider theme={chipTheme()}>
      <MuiChip
        {...props}
        deleteIcon={(
          <ClearIcon style={{ color: '#008BCF' }} />
        )}
      />
    </ThemeProvider>
  );
};

const checkboxTheme = () =>
  createTheme({
    typography: {
      fontFamily: [
        'Poppins',
        'Roboto',
        'Arial',
      ].join(','),
    },
    palette: {
      primary: {
        main: '#37AEFD',
      },
    },
  });

export const Checkbox = (props: CheckboxProps) => {
  return (
    <ThemeProvider theme={checkboxTheme()}>
      <MuiCheckbox
        checkedIcon={<CheckedIcon/>}
        icon={<UncheckedIcon/>}
        indeterminateIcon={<IndeterminateIcon/>}
        {...props}
      />
    </ThemeProvider>
  );
};
