import React from 'react';
import { DialogContentText } from '@mui/material';

import { merge, isNil } from 'ramda';
import Modal from '../components/Modal/Modal.component';
import HeaderButton from '../components/HeaderButton/HeaderButton.component';

export let popup;
export let popupWithCancel;
export let popupWithCustoms;

const def = {
  open: false,
  title: '',
  msg: '',
  action: null,
  customContent: null,
  customButtons: null,
  className: '',
  size: '',
  confirmColor: 'primary' | 'danger'
};
export class PopupTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = def;
  }

  componentDidMount() {
    popup = (title = null, msg) => {
      this.setState({
        title,
        msg,
        open: true,
        customButtons: null,
        customContent: null,
      });
    };
    popupWithCancel = (title = null, msg, act, confirmColor) => {
      this.setState({
        title,
        msg,
        open: true,
        action: act,
        customButtons: null,
        customContent: null,
        confirmColor,
      });
    };
    popupWithCustoms = ({
      title = null,
      customButtons,
      customContent,
      size,
      className,
    }) => {
      this.setState({
        open: true,
        customButtons,
        customContent,
        title,
        action: null,
        size,
        className,
      });
    };
  }

  render() {
    const {
      open,
      title,
      msg,
      action,
      customButtons,
      customContent,
      className,
      size,
      confirmColor,
    } = this.state;

    return (
      <Modal
        open={open}
        onClose={() => this.setState(def)}
        title={title}
        className={className || 'sked-test-custom-modal'}
        size={size || 'xs'}
        maxWidth={size ? undefined : 1100}
        buttons={[
          !isNil(action) && !customButtons &&
            <HeaderButton
              color={confirmColor || 'primary'}
              onClick={() => {
                console.log('execute');
                action();
                this.setState(def);
              }}
              title="Yes"
              className='sked-test-custom-modal-button-yes'
            />,
          isNil(action) && customButtons &&
            customButtons({
              state: this.state,
              setState: (v) => this.setState(merge(this.state, v)),
              close: () => this.setState(def),
            })
        ]}
      >
        <div>
          {customContent ?
            customContent({
              state: this.state,
              setState: (v) => this.setState(merge(this.state, v)),
              close: () => this.setState(def),
            })
            :
            <DialogContentText id="alert-dialog-description">
              {msg.split('\n').map((m, i) => {
                return (
                  <div key={i}>
                    {m}
                  </div>
                );
              })}
            </DialogContentText>}
        </div>
      </Modal>
    );
  }
}
