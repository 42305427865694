import * as at from '../../../../actionTypes.js';
import * as R from 'ramda';

const init = {
  state: 'CREATE',
  id: null,
  busy: false,
  page: 1,
  typeStatus: 'Some',
  types: [],
  professionals: [],
  selectedTypes: [],
  selectedPro: {},
  value: 1,
  unit: { name: 'Day', id: 2 },
  beforeAfter: 'Before',
  isEmail: false,
  isSMS: false,
  isPush: false,
  isEnabled: false,
  messageName: '',
  subject: '',
  body: '',
  email: {
    subject: '',
    body: '',
    html: null,
    attachments: [],
    shouldFrame: true,
  },
  sms: {
    body: '',
    attachments: [],
  },
  push: {
    subject: '',
    body: '',
  },
  attachments: [],
  sendAt: '08:00:00',
  delay: 5,
  changeType: [],
  source: [],
  isTemplate: false,
  includeInactive: false,
  status: 'LOAD',
  campaign: undefined,
  justSentMsgId: undefined,
  wasTemplate: false,
};

export default function reducer(editRecurring = init, action) {
  switch (action.type) {
    case at.RECURRING_EDIT_PATCH: {
      return R.merge(editRecurring, action.data);
    }
    case at.RECURRING_EDIT_ADD_TYPE: {
      if (action.data.type.name === 'All') {
        return R.evolve({
          selectedTypes: R.pipe(
            R.pathOr([], ['selectedPro', 'types']),
            R.tail,
            R.filter((val) => !R.find(R.propEq('id', val.id))(editRecurring.selectedTypes)),
            R.concat
          )(editRecurring),
        }, editRecurring);
      } else if (R.pipe(
        R.propOr([], 'selectedTypes'),
        R.find(R.propEq('id', action.data.type.id)))(editRecurring)) {
        return editRecurring;
      }
      return R.evolve({
        selectedTypes: R.concat([action.data.type])
      }, editRecurring);
    }
    case at.RECURRING_EDIT_ADD_TYPES: {
      return R.evolve({
        selectedTypes: R.pipe(
          R.filter((val) => !R.find(R.propEq('id', val.id))(editRecurring.selectedTypes)),
          R.concat)(action.data.selectedTypes),
        typeStatus: () => action.data.typeStatus,
      }, editRecurring);
    }
    case at.RECURRING_EDIT_REMOVE_TYPE: {
      return R.evolve({
        selectedTypes: R.remove(action.data.index, 1),
        typeStatus: () => 'Some',
      }, editRecurring);
    }
    case at.RECURRING_EDIT_REMOTE_GET_TYPES:
      if (action.state === 'REQUEST') {
        return R.merge(editRecurring, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        const {
          types,
          professionals,
        } = action.data;
        return R.merge(editRecurring, {
          types,
          professionals,
          state: 'CREATE',
          busy: false,
        });
      } else if (action.state === 'ERROR') {
        return R.merge(editRecurring, {
          busy: false,
        });
      }
      return editRecurring;
    case at.RECURRING_EDIT_RESET:
      return R.merge(editRecurring, init);
    case at.RECURRING_EDIT_REMOTE_GET:
      if (action.state === 'REQUEST') {
        return R.merge(editRecurring, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        return R.merge(editRecurring, R.merge(action.data, { busy: false, status: 'CHECK_ERRORS' }));
      } else if (action.state === 'ERROR') {
        return R.merge(editRecurring, {
          busy: false,
        });
      }
      return editRecurring;
    case at.RECURRING_EDIT_SEND:
      console.log(action);
      if (action.state === 'REQUEST') {
        return R.merge(editRecurring, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        return R.evolve({
          state: R.always('SENT'),
          busy: R.F,
          justSentMsgId: R.always(action.data.id),
          wasTemplate: R.always(editRecurring.isTemplate),
        }, editRecurring);
      } else if (action.state === 'ERROR') {
        return R.merge(editRecurring, {
          busy: false,
        });
      }
      return editRecurring;
    case at.RECURRING_EDIT_ADD_CAMPAIGN: {
      if (action.state === 'REQUEST') {
        return R.merge(editRecurring, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        const { campaign } = action.data;
        const smallCampaign = {
          listId: campaign.id,
          listName: campaign.name,
          id: campaign.campaign.id,
        };
        return R.merge(editRecurring, {
          campaign: smallCampaign,
        }, editRecurring);
      } else if (action.state === 'ERROR') {
        return R.merge(editRecurring, {
          busy: false,
        });
      }
      return editRecurring;
    }
    default:
      return editRecurring;
  }
}
