import * as at from '../../../../actionTypes.js';
import { remoteAction } from '../../../../services/actionService.js';
import api from '../../../../services/api.js';
import * as R from 'ramda';
import {
  LocalDate,
} from '@js-joda/core';
import { now, tzParse, format } from '../../../../services/joda.js';
import { convertEmail } from '../../../../services/utilities.js';
import axios from 'axios';
import { getCampaign } from '../../../ClientLists/clientlists.actions';

export const messagesPatch = (prop, value) => (dispatch) =>
  dispatch({
    type: at.NEW_MESSAGE_PATCH,
    data: {
      [prop]: value,
    }
  });

export const messageMassPatch = (data) => (dispatch) =>
  dispatch({
    type: at.NEW_MESSAGE_PATCH,
    data,
  });


export const getTypesApi = () =>
  api.get('appointmentType').then((types) => {
    return api.get('professional').then((pros) => {
      const professionals = R.filter(({ isHidden }) => !isHidden)(pros);
      return {
        professionals,
        types: R.filter(({ professionalId }) =>
          R.find(R.propEq('id', professionalId))(professionals))(types),
      };
    });
  });

export const getTypes = () => remoteAction({
  type: at.NEW_MESSAGE_REMOTE_GET_TYPES,
  action: () => getTypesApi(),
});

export const addType = ({ type }) => (dispatch) =>
  dispatch({
    type: at.NEW_MESSAGE_ADD_TYPE,
    data: {
      type
    }
  });

export const addAllTypes = ({ pros, type }) => (dispatch) => {
  if (type.name === 'All') {
    return dispatch({
      type: at.NEW_MESSAGE_ADD_TYPES,
      data: {
        selectedTypes: R.pipe(R.map((pro) => R.tail(pro.types)), R.flatten)(R.tail(pros)),
        typeStatus: 'All',
      }
    });
  }
  return dispatch({
    type: at.NEW_MESSAGE_ADD_TYPES,
    data: {
      selectedTypes: R.pipe(
        R.map(
          ({ types }) =>
            R.filter(
              (typ) =>
                typ.name === type.name)(types)),
        R.flatten
      )(R.tail(pros)),
      typeStatus: 'Some',
    }
  });
};

export const selectPro = (pro) => (dispatch) =>
  dispatch({
    type: at.NEW_MESSAGE_PATCH,
    data: {
      selectedPro: pro,
    }
  });

export const removeType = ({ index }) => (dispatch) => {
  return dispatch({
    type: at.NEW_MESSAGE_REMOVE_TYPE,
    data: {
      index
    }
  });
};

export const clearSelected = (prop) => (dispatch) =>
  dispatch({
    type: at.NEW_MESSAGE_PATCH,
    data: {
      [prop]: []
    }
  });

const CancelToken = axios.CancelToken;
let cancel;
export const getClients = (data) => {
  if (data.query) {
    data.query = {
      ...data.query,
      isLead: false,
      sortBy: [{
        direction: 'Asc',
        field: 'LastName'
      }, {
        direction: 'Asc',
        field: 'FirstName'
      }]
    };
  }
  if (cancel)
    cancel('User aborted request.');
  return remoteAction({
    type: at.NEW_MESSAGE_REMOTE_GET_CLIENTS,
    action: () => api.post('client/query', data, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
      })
    }).then((d) => {
      return {
        ...d,
        page: data.page,
      };
    })
  });
};
let leadCancel;
export const getLeads = (data) => {
  if (data.query) {
    data.query = {
      ...data.query,
      isLead: true,
      sortBy: [{
        direction: 'Desc',
        field: 'Created'
      }]
    };
  }
  if (leadCancel)
    leadCancel('User aborted request.');
  return remoteAction({
    type: at.NEW_MESSAGE_REMOTE_GET_LEADS,
    action: () => api.post('client/query', data, {
      cancelToken: new CancelToken((c) => {
        leadCancel = c;
      })
    }).then((d) => {
      return {
        ...d,
        page: data.page,
      };
    })
  });
};

export const addClient = (client) => (dispatch) =>
  dispatch({
    type: at.NEW_MESSAGE_ADD_CLIENT,
    data: {
      client,
    }
  });

export const addLead = (lead) => (dispatch) =>
  dispatch({
    type: at.NEW_MESSAGE_ADD_LEAD,
    data: {
      lead,
    }
  });

export const removeClient = ({ index }) => (dispatch) =>
  dispatch({
    type: at.NEW_MESSAGE_REMOVE_CLIENT,
    data: {
      index
    }
  });

export const removeLead = ({ index }) => (dispatch) =>
  dispatch({
    type: at.NEW_MESSAGE_REMOVE_LEAD,
    data: {
      index
    }
  });


const removeHtmlIfPlainEmail = message => {
  const isPlainEmail = !!R.pathOr(false, ['email', 'body', 'PlainText'], message);
  if (isPlainEmail) {
    const emailBodyPath = R.lensPath(['email', 'body']);
    return R.over(emailBodyPath, R.omit(['HTML']), message);
  }
  return message;
};

const enableMessage = message => {
  return R.assoc('isEnabled', true, message);
};

const disableMessage = message => {
  return R.assoc('isEnabled', false, message);
};

const makeTemplate = message => {
  return R.assoc('isTemplate', true, message);
};

export const sendMessage = ({ message }) => remoteAction({
  type: at.NEW_MESSAGE_SEND,
  action: () => api.post('message', R.pipe(enableMessage, removeHtmlIfPlainEmail)(message))
});

export const sendDraftMessage = ({ message, id }) => {
  const msg = enableMessage(message);
  return remoteAction({
    type: at.NEW_MESSAGE_SEND,
    action: () => api.put(`message/${id}`, msg)
  });
};

export const saveMessage = (message, id) => {
  const newMessage = R.pipe(disableMessage, removeHtmlIfPlainEmail)(message);
  return remoteAction({
    type: at.NEW_MESSAGE_SEND,
    action: () => id === '0' ?
      api.post('message', newMessage) :
      api.put(`message/${id}`, newMessage)
  });
};

export const saveTemplate = (message, id, isTemplate) => {
  const newMessage = R.pipe(
    makeTemplate,
    disableMessage,
    removeHtmlIfPlainEmail
  )(message);

  return remoteAction({
    type: at.NEW_MESSAGE_SEND,
    action: () => id === '0' || !isTemplate ?
      api.post('message', newMessage) :
      api.put(`message/${id}`, newMessage).catch(() => {
        return api.post('message', newMessage);
      })
  });
};


export const cancelMessage = () => remoteAction({
  type: at.NEW_MESSAGE_SEND,
  action: () => new Promise((res) => res()),
});

export const reset = () => (dispatch) =>
  dispatch({
    type: at.NEW_MESSAGE_RESET,
  });

const adjustDate = (obj, date) => R.cond([
  [R.has('Day'), () => date.plusDays(obj.Day)],
  [R.has('Week'), () => date.plusWeeks(obj.Week)],
  // [R.has('Hour'), () => date.plusHours(obj.Hour)],
  // [R.has('Minute'), () => date.plusMinutes(obj.Minute)],
  [R.T, () => date]
])(obj);
const receiversParse = (receivers) => {
  return R.cond([
    [R.is(String), R.always({
      filter: receivers,
      startDate: now('date'),
      endDate: now('date').plusDays(7),
    })],
    [R.has('Everyone'), R.always({
      filter: 'Everyone',
    })],
    [R.has('DateRange'), () => {
      const date = now('date');
      const range = R.propOr({}, 'DateRange')(receivers);
      let start = R.propOr(false, 'start')(range);
      let end = R.propOr(false, 'end')(range);
      if (typeof start === 'object') {
        start = adjustDate(start, date);
        end = adjustDate(end, date);
      } else if (typeof start === 'string') {
        start = LocalDate.parse(start);
        end = LocalDate.parse(end);
      }
      return {
        filter: 'DateRange',
        startDate: start ? start : date,
        endDate: end ? end : date,
        types: range.appointmentTypes,
      };
    }],
    [R.has('Clients'), R.always({
      filter: 'Clients',
      clients: R.propOr([], 'Clients')(receivers),
    })],
    [R.has('ClientTag'), R.always({
      filter: 'ClientTag',
      tags: R.propOr([], 'ClientTag')(receivers),
    })]
  ])(receivers);
};

const getAttachments = (attachmentIds) => {
  return Promise.all(R.map((aIds) => {
    return api.post('attachment', { attachmentIds: aIds });
  })(attachmentIds));
};

export const loadingMessage = (message, tz, type = 'edit') => {
  const receivers = receiversParse(
    R.path(
      ['messageType', 'OneTime', 'receivers'],
      message
    ));
  return Promise.all([
    getTypesApi(),
    getAttachments([
      R.pathOr([], ['email', 'attachmentIds'], message),
      R.pathOr([], ['sms', 'attachmentIds'], message),
    ]),
    getCampaign(message.campaignId),
  ]).then(([{ types, professionals }, [emailOfficeAttach, smsOfficeAttach], list]) => {
    const emailAttachments = R.propOr([], 'officeAttachments')(emailOfficeAttach);
    const smsAttachments = R.propOr([], 'officeAttachments')(smsOfficeAttach);
    const subject = R.cond([
      [R.prop('email'), () => message.email.subject],
      [R.prop('push'), () => message.push.subject],
      [R.prop('sms'), () => ''],
      [R.T, () => R.propOr('', 'subject')(message)],
    ])(message);
    const body = R.cond([
      [R.prop('email'), () => R.ifElse(
        R.is(Object),
        R.propOr('', 'PlainText'),
        R.identity,
      )(message.email.body)],
      [R.prop('push'), () => message.push.body],
      [R.prop('sms'), () => message.sms.body],
      [R.T, () => R.propOr('', 'body')(message)],
    ])(message);
    const def = type === 'edit' ? {
      subject: '',
      body: '',
    } : false;
    const smsDef = R.ifElse(
      R.identity,
      R.dissoc('subject'),
      R.identity
    )(def);
    const schedule = R.ifElse(
      R.has('messageType'),
      R.path(['messageType', 'OneTime', 'scheduledFor']),
      R.always(null)
    )(message);
    const forDate = tzParse(schedule ? schedule : '1970-01-01T00:00:00Z', tz);
    const maybeEmail = R.propOr(def, 'email')(message);
    const maybeSms = R.propOr(smsDef, 'sms')(message);
    const email = R.ifElse(
      R.identity,
      R.always({
        subject: maybeEmail.subject,
        body: R.pathOr('', ['body', 'PlainText'])(maybeEmail),
        html: R.pathOr(null, ['body', 'HTML', 'body'])(maybeEmail),
        shouldFrame: R.pathOr(true, ['body', 'HTML', 'shouldFrame'])(maybeEmail),
        attachments: emailAttachments
      }),
      R.always(R.merge(def, { shouldFrame: true }))
    )(maybeEmail);
    const sms = R.ifElse(
      R.identity,
      R.assoc('attachments', smsAttachments),
      R.always(smsDef)
    )(maybeSms);
    const scheduledFor = type === 'view' ?
      schedule :
      R.ifElse(
        R.identity,
        R.always('now'),
        R.always(format(forDate, 'yyyy-MM-dd\'T\'HH:mm:ss'))
      )(forDate.isBefore(now('tz')));
    const campaign = list ? {
      id: list.campaign.id,
      listName: list.campaign.displayName,
    } : undefined;
    if (receivers?.filter === 'DateRange') {
      if (R.hasPath(['types', 'All'])(receivers)) {
        return {
          messageName: message.name,
          body,
          subject,
          isEmail: Boolean(message.email),
          isPush: Boolean(message.push),
          isSMS: Boolean(message.sms),
          email,
          sms,
          push: R.propOr(def, 'push')(message),
          scheduledFor,
          selectedTypes: R.map(R.prop('id'))(types),
          startDate: receivers.startDate,
          endDate: receivers.endDate,
          filter: receivers.filter,
          types,
          professionals,
          templateId: undefined,
          campaign,
          folder: message.folder,
        };
      }
      return {
        messageName: message.name,
        body,
        subject,
        isEmail: Boolean(message.email),
        isPush: Boolean(message.push),
        isSMS: Boolean(message.sms),
        email,
        sms,
        push: R.propOr(def, 'push')(message),
        scheduledFor,
        selectedTypes: R.pathOr([], ['types', 'Some'])(receivers),
        startDate: receivers.startDate,
        endDate: receivers.endDate,
        filter: receivers.filter,
        types,
        professionals,
        templateId: undefined,
        campaign,
        folder: message.folder,
      };
    } else if (receivers?.filter === 'Clients') {
      if (!receivers.clients || R.isEmpty(receivers.clients)) {
        return Promise.resolve({
          messageName: message.name,
          body,
          subject,
          isEmail: Boolean(message.email),
          isPush: Boolean(message.push),
          isSMS: Boolean(message.sms),
          email,
          sms,
          push: R.propOr(def, 'push')(message),
          scheduledFor,
          selectedClients: [],
          filter: 'Clients',
          types,
          professionals,
          templateId: undefined,
          campaign,
          folder: message.folder,
        });
      }
      return Promise.all([
        api.post('client/query', {
          query: {
            clientIds: receivers.clients,
            sortBy: [{
              direction: 'Asc',
              field: 'LastName'
            }, {
              direction: 'Asc',
              field: 'FirstName'
            }],
            isLead: false
          },
          perPage: R.length(receivers.clients),
          page: 1,
        }),
        api.post('client/query', {
          query: {
            clientIds: receivers.clients,
            sortBy: [{
              direction: 'Asc',
              field: 'LastName'
            }, {
              direction: 'Asc',
              field: 'FirstName'
            }],
            isLead: true
          },
          perPage: R.length(receivers.clients),
          page: 1,
        }),

      ]).then(([clients, leads]) => {
        return {
          messageName: message.name,
          body,
          subject,
          isEmail: Boolean(message.email),
          isPush: Boolean(message.push),
          isSMS: Boolean(message.sms),
          email,
          sms,
          push: R.propOr(def, 'push')(message),
          scheduledFor,
          selectedClients: clients.data,
          selectedLeads: leads.data,
          filter: leads.data?.length ? 'Leads' : 'Clients',
          types,
          professionals,
          templateId: undefined,
          campaign,
          folder: message.folder,
        };
      });
    } else if (receivers?.filter === 'ClientTag') {
      return api.get('client/tag').then((tags) => {
        const operator = R.ifElse(
          R.has('And'),
          R.always('And'),
          R.always('Or'))(receivers.tags);
        return {
          messageName: message.name,
          body,
          subject,
          isEmail: Boolean(message.email),
          isPush: Boolean(message.push),
          isSMS: Boolean(message.sms),
          email,
          sms,
          push: R.propOr(def, 'push')(message),
          scheduledFor,
          filter: 'ClientTag',
          types,
          professionals,
          operator,
          tags: R.map((id) => R.find(R.propEq('tagId', id))(tags))(R.propOr([], operator)(receivers.tags)),
          templateId: undefined,
          campaign,
        };
      });
    }
    return new Promise((res) => {
      res();
    }).then(() => {
      return {
        messageName: message.name,
        body,
        subject,
        isEmail: Boolean(message.email),
        isPush: Boolean(message.push),
        isSMS: Boolean(message.sms),
        email,
        sms,
        push: R.propOr(def, 'push')(message),
        scheduledFor,
        filter: 'Everyone',
        types,
        professionals,
        isTemplate: message.isTemplate,
        templateId: undefined,
        campaign,
      };
    });
  });
};

export const loadMessage = (id, tz, features) => remoteAction({
  type: at.NEW_MESSAGE_REMOTE_GET,
  action: () => api.get(`message/${id}`).then((message) => {
    const m = convertEmail(message, features);
    return loadingMessage(m, tz);
  })
});
