import * as at from '../../actionTypes';
import {
  merge, adjust, update, findIndex, propEq, evolve, remove,
  set, compose, lensIndex, lensProp, over, append
} from 'ramda';

const init = {
  cellHeight: 35,
  cellWidth: 100,
  nameDisplay: 'abbr_f',
  sequence: 0,

  schedules: [{ id: -1, name: 'All Schedules' }],
  appointments: [],
  officeHours: [],

  loading: true,
  errorMessage: '',
  init: true
};

export default (state = init, action) => {
  switch (action.type) {
    case at.SKED_CALENDAR_UPDATE_SETTINGS: {
      return merge(state, action.payload);
    }
    case at.SKED_REMOTE_GET_CALENDAR_INIT: {
      if (action.state === 'REQUEST') {
        return {
          ...state,
          loading: true,
          errorMessage: ''
        };
      } else if (action.state === 'RESPONSE') {
        return {
          ...state,
          ...action.data,
          loading: false,
          init: false
        };
      }
      return {
        ...state,
        ...action.data,
        loading: false,
        errorMessage: 'Error getting calendar data'
      };
    }
    case at.SKED_REMOTE_GET_CALENDAR_APTS: {
      if (action.state === 'REQUEST') {
        return {
          ...state,
          sequence: state.sequence + 1,
          loading: true,
          errorMessage: ''
        };
      } else if (action.state === 'RESPONSE') {
        if (state.sequence > action.data.sequence) {
          return state;
        }
        return {
          ...state,
          appointments: action.data.appointments,
          loading: false
        };
      }
      return {
        ...state,
        ...action.data,
        loading: false,
        errorMessage: 'Error getting calendar data'
      };
    }
    case at.SKED_REMOTE_RESKED_APT: {
      if (action.state === 'RESPONSE') {
        return evolve({
          appointments: apts => {
            const apt = {
              ...action.data,
              time: action.data.time
            };
            const idx = findIndex(propEq('id', apt.id), apts);
            return update(idx, apt, apts);
          }
        }, state);
      }
      return state;
    }
    case at.SKED_RESKED_APT: {
      return evolve({
        appointments: apts => {
          const apt = {
            ...action.data,
            time: action.data.time
          };
          const idx = findIndex(propEq('id', apt.id), apts);
          return update(idx, apt, apts);
        }
      }, state);
    }
    case at.SKED_CANCEL_APT: {
      return evolve({
        appointments: apts => {
          const idx = findIndex(propEq('id', action.data.id), apts);
          if (idx > -1) {
            return remove(idx, 1, apts);
          } else {
            return apts;
          }
        }
      }, state);
    }
    case at.SKED_REMOTE_APT_ARRIVE: {
      if (action.state === 'RESPONSE') {
        return evolve({
          appointments: apts => {
            const idx = findIndex(propEq('id', action.data.id), apts);
            const updateApt = a => ({
              ...a,
              status: { [action.data.status]: [] }
            });
            return adjust(idx, updateApt, apts);
          }
        }, state);
      }
      return state;
    }
    case at.SKED_CALENDAR_PUT_APPT: {
      const appt = action.data;
      const idx = findIndex(propEq('id', appt.id), state.appointments);
      if (idx >= 0) {
        return set(compose(lensProp('appointments'), lensIndex(idx)), appt, state);
      }
      return over(lensProp('appointments'), append(appt), state);
    }
    default: {
      return state;
    }
  }
};
