import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    '@media print': {
      display: 'none'
    },
  },
  switchContainer: {
    display: 'flex',
    background: 'linear-gradient(45deg, hsla(200, 100%, 41%, 1) 0%, hsla(200, 100%, 45%, 1) 72%, hsla(200, 100%, 50%, 1) 100%)',
    borderRadius: 0,
    paddingTop: 10,
    paddingBottom: 10,
    marginTop: 6,

    '&:hover': {
      background: 'linear-gradient(45deg, hsla(200, 100%, 41%, 1) 0%, hsla(200, 100%, 45%, 1) 72%, hsla(200, 100%, 50%, 1) 100%)',
    }
  },
  switchContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',

    '& > p': {
      fontSize: 16,
      color: '#FFF',
      fontWeight: 400,
    },
    '& > span': {
      fontSize: 13,
      color: '#FFF'
    }
  },
  arrowIcon: {
    width: 17,
    height: 17,
  },
  navigateIcon: {
    width: 20,
    height: 20,
  }
}));
